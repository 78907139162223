import React, { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import App from '../App/App';

const AppWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <App />;
};
export default withRouter(AppWrapper);
