import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { IRouteData, ISeasonTravelApiModel } from '../../models';
import SeasonService from '../../api/SeasonService';
import './Seasons.scss';
import { TravelRouteType, WaypointType } from './SeasonConstants';

const DIALOG_HEADER = 'Travel Routes Data';
const FOOTER_LOAD_TEXT = "Uploading data. Please Don't Close This Window";

type LocationIconProps = {
  locationType: WaypointType | undefined;
};

type ComponentProperties = {
  seasonId: number;
  travelRoutesData: IRouteData[];
  displayDialog: boolean;
};

const LocationIcon: React.FC<LocationIconProps> = (props) => {
  const { locationType } = props;

  switch (locationType) {
    case WaypointType.PICKUP:
      return <i className="pi pi-sign-in icon-info-color location-icon" />;
    case WaypointType.DROP_OFF:
      return <i className="pi pi-sign-out icon-light-color location-icon" />;
    case WaypointType.START_POINT:
      return <i className="pi pi-flag icon-success-color location-icon" />;
    case WaypointType.END_POINT:
      return <i className="pi pi-eject icon-warn-color location-icon" />;

    default:
      return <i className="pi pi-arrow-left" />;
  }
};

const TravelRoutesModal: React.FC<ComponentProperties> = (props) => {
  const { seasonId, travelRoutesData, displayDialog } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [seasonTravelData, setSeasonTravelData] = useState<ISeasonTravelApiModel[]>([]);

  const toast = useRef<Toast>(null);

  const loadProcessedData = useCallback(
    (seasonData: ISeasonTravelApiModel[] | undefined) => {
      const travelData: ISeasonTravelApiModel[] = travelRoutesData.map((data) => {
        const { id: startPointId, locationName: startPointName, locationType: startPointType } = data.startPoint;
        const { id: endPointId, locationName: endPointName, locationType: endPointType } = data.endPoint;

        let id;
        let travelRouteType =
          endPointType === WaypointType.DROP_OFF ? TravelRouteType.DROP_OFF : TravelRouteType.PICKUP;
        let travelFeeAmount = 0;
        let active = false;

        if (seasonData) {
          const storedTravelData = seasonData.find(
            (s) => s.startPointId === startPointId && s.endPointId === endPointId,
          );
          if (storedTravelData) {
            id = storedTravelData.id || 0;
            travelFeeAmount = storedTravelData.travelFeeAmount;
            active = storedTravelData.active;
            travelRouteType = storedTravelData.travelRouteType;
          }
        }

        return {
          id,
          seasonId,
          startPointId,
          startPointName,
          startPointType,
          endPointId,
          endPointName,
          endPointType,
          travelRouteType,
          travelFeeAmount,
          active,
        };
      });

      setSeasonTravelData(travelData);
    },
    [seasonId, travelRoutesData],
  );

  useEffect(() => {
    if (!seasonId || !showDialog) return;
    setIsLoading(true);
    let seasonData: ISeasonTravelApiModel[] = [];

    SeasonService.getTravelRoutesById(seasonId)
      .then((apiData) => {
        seasonData = apiData;
        loadProcessedData(seasonData);
      })
      .catch(() => {
        const tst = toast?.current;
        tst?.show({
          severity: 'error',
          summary: 'Data fetching failed for travel routes',
        });
      })
      .finally(() => setIsLoading(false));
  }, [seasonId, showDialog, travelRoutesData, loadProcessedData]);

  useEffect(() => {
    setShowDialog(displayDialog);
  }, [displayDialog]);

  const handleModalHide = () => {
    setShowDialog(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const tst = toast?.current;
    SeasonService.updateTravelRoutesData(seasonTravelData)
      .then((seasonData) => {
        tst?.show({ severity: 'success', summary: 'Travels data updated successfully' });
        loadProcessedData(seasonData);
        setShowDialog(false);
      })
      .catch(() => {
        tst?.show({
          severity: 'error',
          summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const renderFooter = () => {
    return isLoading ? (
      <div className="no-close-warn">{FOOTER_LOAD_TEXT}</div>
    ) : (
      <div>
        {!!seasonId && (
          <Button
            label="Submit"
            icon="pi pi-upload"
            onClick={() => handleSubmit()}
            className="p-button-primary p-button-lg p-mr-4"
          />
        )}
        <Button label="Close" icon="pi pi-times" onClick={() => handleModalHide()} className="p-button-danger" />
      </div>
    );
  };

  const updateTravelData = ({
    index,
    field,
    value,
  }: {
    index: number;
    field: keyof ISeasonTravelApiModel;
    value: number | boolean;
  }) => {
    const newData = [...seasonTravelData];
    let newDataRow = newData[index];
    newDataRow = { ...newDataRow, [field]: value };
    if (field === 'travelFeeAmount') {
      newDataRow = { ...newDataRow, active: newDataRow.travelFeeAmount > 0 };
    }
    newData[index] = newDataRow;
    setSeasonTravelData(newData);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="routes-btn-container">
        {seasonId ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowDialog(true);
            }}
            label="Update Travel Routes Data"
            className="p-button-md p-button-secondary p-button-outlined"
          />
        ) : (
          <div className="notice-text">Create the season to update travel route details</div>
        )}
      </div>
      <Dialog
        header={DIALOG_HEADER}
        visible={showDialog}
        onHide={handleModalHide}
        className="modal-container"
        closable={false}
        closeOnEscape={false}
        footer={renderFooter}
      >
        {isLoading ? (
          <div className="data-loader">
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }} />
            <p>Loading...</p>
          </div>
        ) : (
          <div>
            <div className="icon-tray">
              <span>
                <LocationIcon locationType={WaypointType.START_POINT} /> Trek Start Point
              </span>
              <span>
                <LocationIcon locationType={WaypointType.END_POINT} /> Trek End Point
              </span>
              <span>
                <LocationIcon locationType={WaypointType.PICKUP} /> Pick-up Location
              </span>
              <span>
                <LocationIcon locationType={WaypointType.DROP_OFF} /> Drop-off Location
              </span>
              <hr />
            </div>
            <table className="travels-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Travel Route Type</th>
                  <th>Start (From)</th>
                  <th>End (To)</th>
                  <th>Travel Journey Route</th>
                  <th>Travel Charge Amount</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {seasonTravelData.map((data, index) => {
                  return (
                    <tr key={`${data.id}_${data.startPointId}_${data.endPointId}`}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <span className="status-dark route-type">{data.travelRouteType}</span>
                      </td>
                      <td>
                        {data.startPointName}
                        <LocationIcon locationType={data.startPointType} />
                      </td>
                      <td>
                        {data.endPointName}
                        <LocationIcon locationType={data.endPointType} />
                      </td>
                      <td>
                        <span className="journey-point">{data.startPointName}</span>
                        to
                        <span className="journey-point">{data.endPointName}</span>
                      </td>
                      <td>
                        <InputNumber
                          value={data.travelFeeAmount}
                          onValueChange={(e) => updateTravelData({ index, field: 'travelFeeAmount', value: e.value })}
                          mode="currency"
                          currency="INR"
                          locale="en-IN"
                          className="price-input"
                        />
                      </td>
                      <td>
                        <Checkbox
                          onChange={(e) => updateTravelData({ index, field: 'active', value: e.checked })}
                          checked={data.active}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default TravelRoutesModal;
