export const TrekDifficulty = [
  { name: 'Easy', code: 'EASY' },
  { name: 'Easy Moderate', code: 'EASY_MODERATE' },
  { name: 'Moderate', code: 'MODERATE' },
  { name: 'Moderate Difficult', code: 'MODERATE_DIFFICULT' },
  { name: 'Difficult', code: 'DIFFICULT' },
];

export enum UserRole {
  GROUND_COORDINATOR = 'GROUND_COORDINATOR',
  TRANSPORT_COORDINATOR = 'TRANSPORT_COORDINATOR',
  SLOPE_MANAGER = 'SLOPE_MANAGER',
}

export enum PaymentGatewayProvider {
  PAYU = 'PAYU',
  PHONEPE = 'PHONEPE',
  STRIPE = 'STRIPE',
  JUSPAY = 'JUSPAY',
}
