import UserService from '../api/UserService';

interface Header {
  Accept: string | 'application/json';
  Authorization: string | undefined;
}

export function getAuthorizationHeader(): string | undefined {
  return `Bearer ${UserService.getToken()}`;
}

export function getHeaderWithBearerToken(): Header {
  if (UserService.tokenExpired()) {
    UserService.doLogout();
  }
  UserService.refreshToken();
  return {
    Accept: 'application/json',
    Authorization: getAuthorizationHeader(),
  };
}
