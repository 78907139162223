/* eslint-disable */
import React, { useRef, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import withLoader from '../../helpers/withloader';
import { ISpecialTrekTypeModel } from '../../models/Trek/SpecialTrekModel';
import specialTrekTypeService from '../../api/SpecialTrekTypeService';

const SpecialTrekTypeDetail: React.FC<RouteComponentProps> = ({ history, match }) => {
  const defaultValues = {
    name: '',
  };

  const toast = useRef(null);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required('Special Trek Type Name is required'),
      }),
    [],
  );

  const { handleSubmit, reset, control, errors, formState } = useForm<ISpecialTrekTypeModel>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: defaultValues,
  });

  const onSubmit = (data: ISpecialTrekTypeModel) => {
    return createSpecialTrekType(data);
  };

  const createSpecialTrekType = (data: ISpecialTrekTypeModel) => {
    return specialTrekTypeService
      .create(data)
      .then(() => {
        history.push('.');
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res.response.data.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Creation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const validationSummary = (
    <div className="validation-summary-error">
      <pre>
        {Object.keys(errors).length > 0 && (
          <div>
            Please fill in the following required information:
            <ul>
              {Object.keys(errors).map((field) => (
                <li>{field}</li>
              ))}
            </ul>
          </div>
        )}
      </pre>
    </div>
  );

  const handleCancelClick = () => {
    history.push('.');
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
        <Toast ref={toast}></Toast>
        <div className="d-flex align-items-center details-title">
          <div className="flex-grow-1">
            <div className="display-1">{'Add Special Trek Type'}</div>
          </div>
          <div>
            <Link to="/special-trek-types">
              <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }}></i>
            </Link>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-12 p-md-12 p-lg-6">
            <div className="card p-fluid">
              {validationSummary}
              <div className="p-field p-grid required">
                <label htmlFor="name" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                  Name:
                </label>
                <div className="p-col-12 p-md-10">
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ onChange, value }) => <InputText autoFocus value={value} onChange={onChange} />}
                  />
                  {errors.name && (
                    <span className="p-error">
                      <p>Error:{errors.name?.message}</p>
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="p-cpl-12 p-grid p-justify-end p-mt-4">
              <div className="">
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  className="p-button-warning p-button-lg"
                  onClick={handleCancelClick}
                />
              </div>
              <div className="">
                <Button
                  type="submit"
                  label="Submit"
                  icon="pi pi-check"
                  className="p-mx-2 p-button-lg p-button-secondary"
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withLoader(withRouter(SpecialTrekTypeDetail));
