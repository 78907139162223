import React from 'react';
import UserService from '../../api/UserService';

export const EmptyPage: React.FC = () => {
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <h5>Welcome to {UserService.getName()}</h5>
        </div>
      </div>
    </div>
  );
};
export default EmptyPage;
