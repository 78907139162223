import { IUserDetailsForParticipantListDisplay } from '../../../models/Booking/BookingParticipantsList';

export const ActionType = {
  update: 'update',
  delete: 'delete',
};

export const ActionTrigger = {
  hide: 'hide',
  submit: 'submit',
};

export const getFlagTextColor = (data: IUserDetailsForParticipantListDisplay, props: any): string => {
  const flagFields = ['flag'];
  let textColor = '#495057';
  if (flagFields.includes(props.field)) {
    if (data?.flag) {
      textColor = data.flag.color;
    }
  }
  return textColor;
};
