/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import BookingService from '../../api/BookingService';
import { IBookingComments, IBookingUIModel } from '../../models';
import { InputTextarea } from 'primereact/inputtextarea';
import moment from 'moment';

type ComponentProperties = {
  mode: boolean;
  bookingId: number;
  ownerId: number;
  participantId?: number;
  commentType: string;
  userComments?: () => IBookingComments[];
};

export const BookingComments: React.FC<ComponentProperties> = (props) => {
  const [indexes, setIndexes] = React.useState<number[]>([]);
  const [counter, setCounter] = React.useState(0);
  const [listOfComments, setListOfComments] = useState<IBookingComments[]>([]);

  useEffect(() => {
    if (props.commentType === 'BOOKING') {
      ///get from server and bind
      fetchComments();
    } else if (props.commentType === 'PARTICIPANT') {
      fetchCommentsForAParticipant();
    }
  }, [props.userComments]);

  const fetchCommentsForAParticipant = () => {
    BookingService.fetchParticipantComments(props.participantId).then((data) => {
      let commentsData = data;
      if (props.userComments) {
        let userComments = props.userComments();
        if (!!userComments?.length) {
          userComments.forEach((comment) => {
            commentsData.push(comment);
          });
        }
      }
      setListOfComments(commentsData);
      const arr = Array.from(new Array(data.length), (x, i) => i);
      setIndexes(arr);
      setCounter(arr.length);
    });
  };
  const fetchComments = () => {
    BookingService.getById(props.bookingId).then((bookingData: IBookingUIModel) => {
      const commentsData: IBookingComments[] = [];

      if (props.commentType === 'BOOKING') {
        bookingData?.participants.forEach((participant) => {
          if (participant.userDetailsForDisplay) {
            let { firstName, lastName, comment } = participant?.userDetailsForDisplay;
            let displayName = firstName + ' ' + lastName;
            if (comment) commentsData.push({ ...comment, displayName });
          }
        });
        bookingData?.bookingComments?.forEach((comment) => {
          commentsData.push({
            commentText: comment.commentText,
            createdOn: comment.createdOn,
            userName: comment.userName,
            bookingId: comment.bookingId,
            userId: comment.userId,
            commentType: comment.commentType,
          });
        });
      }
      setListOfComments(commentsData);
      const arr = Array.from(new Array(commentsData.length), (x, i) => i);
      setIndexes(arr);
      setCounter(arr.length);
    });
  };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        commentText: Yup.string().required('Comment is required'),
      }),
    [],
  );

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, setValue, control, errors, formState } = useForm<any>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = (commentData: any) => {
    if (props.commentType == 'BOOKING') {
      BookingService.saveBookingComments(props.bookingId, commentData, props.ownerId).then((res) => {
        fetchComments();
      });
    } else {
      BookingService.saveParticipantComments(
        props.bookingId,
        Number(props.participantId),
        commentData,
        props.ownerId,
      ).then((res) => {
        fetchCommentsForAParticipant();
      });
    }
  };

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
          <div>
            <p></p>
            <Controller
              name="commentText"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => <InputTextarea autoFocus value={value} onChange={onChange} />}
            />
            {errors.commentText && (
              <span className="p-error">
                <p>Error:{errors.commentText?.message}</p>
              </span>
            )}
          </div>
          <div className="p-grid p-justify-end p-my-4 p-mx-2">
            <div className="">
              <Button
                type="submit"
                label="Post"
                icon="pi pi-check"
                className="p-button-lg p-button-secondary"
                disabled={formState.isSubmitting}
              >
                {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
              </Button>
            </div>
          </div>
        </form>
        <div className="">
          <div className="card card-w-title timeline">
            <h5>Posted Comments:</h5>
            <ul className="comment-ul">
              {indexes.map((index) => {
                const fieldName = `campsites[${index}]`;
                const comments = listOfComments[index]
                  ? listOfComments[index]
                  : {
                      commentText: '',
                      userName: '',
                      createdOn: null,
                      commentType: '',
                      displayName: '',
                    };
                const isUserTypeComment: boolean = comments?.commentType === 'USER';
                return (
                  <li key={index}>
                    <div className="activity-link"></div>
                    {/* <div className="timeline-icon">
                                            <i className="pi pi-globe"></i>
                                        </div> */}
                    <div className="timeline-content">
                      <h3>
                        <i className="pi pi-globe"></i> {isUserTypeComment ? comments.displayName : comments.userName}
                      </h3>
                      {isUserTypeComment && (
                        <p>
                          <span className="status-dark">User Level Comment</span>
                        </p>
                      )}
                      <p>{comments.commentText}</p>
                      {isUserTypeComment && (
                        <p>
                          <b>Updated by: {comments.userName}</b>
                        </p>
                      )}
                      <div className="timeline-footer">
                        <i className="pi pi-clock p-px-2"></i>
                        <span
                          style={{
                            fontStyle: 'italic',
                            color: '#ff4b4b',
                          }}
                        >
                          {moment(comments.createdOn).format('DD-MMM-YYYY')}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingComments;
