/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import lookupApi from '../../api/LookupService';
import { getDataWithDebounce } from '../../helpers/utils';

const DEBOUNCE_DELAY: number = 500;
const MIN_LENGTH: number = 3;
const placeholderText = (minLength: number) => `Search by typing at least ${minLength} characters`;

type ComponentProperties = {
  value: any;
  onChange: (...event: any[]) => void;
  displayField: string;
  autoFocus?: boolean;
  placeholder?: string;
  minLength?: number;
  delay?: number;
  searchServiceType?: SearchType;
  itemTemplate?: (item: any) => JSX.Element;
  disabled?: boolean;
};

export type SearchType = 'EMAIL' | 'PHONE_NUMBER' | 'CUSTOMER' | 'USER_WITH_NAME_ONLY';

const AutoCompleteSearchBox: React.FC<ComponentProperties> = (props) => {
  const [searchTextValue, setSearchTextValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const minLength = props.minLength || MIN_LENGTH;
  const placeholder = props.placeholder || placeholderText(minLength);
  const delay = props.delay || DEBOUNCE_DELAY;

  const lookupService = (serviceType: SearchType, queryString: string, signal: AbortSignal) => {
    switch (serviceType) {
      case 'EMAIL':
        lookupApi.getUserByEmail(queryString.toLowerCase(), signal).then((result) => {
          if (searchTextValue.toLowerCase() === queryString.toLowerCase() && !signal.aborted) {
            setSuggestions(result);
          }
        });
        break;

      case 'PHONE_NUMBER':
        lookupApi.getUserByPhoneNumber(queryString.toLowerCase(), signal).then((result) => {
          if (searchTextValue.toLowerCase() === queryString.toLowerCase() && !signal.aborted) {
            setSuggestions(result);
          }
        });
        break;

      case 'CUSTOMER':
        lookupApi.getUserWithStartWith('CUSTOMER', queryString.toLowerCase(), signal).then((result) => {
          if (searchTextValue.toLowerCase() === queryString.toLowerCase() && !signal.aborted) {
            setSuggestions(result);
          }
        });
        break;

      case 'USER_WITH_NAME_ONLY':
        lookupApi.getUserWithStartWithNameOnly(queryString.toLowerCase(), signal).then((result) => {
          if (searchTextValue.toLowerCase() === queryString.toLowerCase() && !signal.aborted) {
            setSuggestions(result);
          }
        });
        break;

      default:
        setSuggestions([]);
    }
  };

  const debouncedFetchSuggestions = getDataWithDebounce(lookupService, DEBOUNCE_DELAY);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (!!minLength && !!props.searchServiceType) {
      if (searchTextValue && searchTextValue.length >= minLength) {
        debouncedFetchSuggestions(props.searchServiceType, searchTextValue, signal);
      }
    }
    // Aborting Unnecessary API Calls in Cleanup
    return () => {
      controller.abort();
    };
  }, [searchTextValue]);

  return (
    <AutoComplete
      autoFocus={props.autoFocus}
      placeholder={placeholder}
      minLength={minLength}
      delay={delay}
      dropdown
      value={props.value}
      onChange={(e) => {
        props.onChange(e.value);
      }}
      suggestions={suggestions}
      field={props.displayField}
      itemTemplate={props.itemTemplate}
      completeMethod={(event) => setSearchTextValue(event.query)}
      disabled={props.disabled}
    />
  );
};

export default AutoCompleteSearchBox;
