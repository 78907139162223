import axios from 'axios';
import { getHeaderWithBearerToken } from '../helpers';
import { ISpecialTrekTypeModel } from '../models/Trek/SpecialTrekModel';

export const specialTrekTypeBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/special-trek-type`;

class SpecialTrekTypeService {

  update = (specialTrekTypeId: number, specialTrekType: ISpecialTrekTypeModel) => {
    const url = `${specialTrekTypeBaseApi}/${specialTrekTypeId}`; 
    return axios.put(url, specialTrekType.name, { 
      headers: { ...getHeaderWithBearerToken(), 'Content-Type': 'text/plain' }
    });
  };  

  getAll = () => {
    const url = `${specialTrekTypeBaseApi}`;
    return axios.get<ISpecialTrekTypeModel[]>(url, { headers: getHeaderWithBearerToken() });
  };

  deleteById = (specialTrekTypeId: number) => {
    const url = `${specialTrekTypeBaseApi}/${specialTrekTypeId}`;
    return axios.delete<ISpecialTrekTypeModel>(url, {
      headers: getHeaderWithBearerToken(),
    });
  };

  create = (specialTrekType: ISpecialTrekTypeModel) => {
    const url = `${specialTrekTypeBaseApi}/${specialTrekType.name}`;
    return axios.post(url, {}, { headers: getHeaderWithBearerToken() });
  };
}

const specialTrekTypeApi = new SpecialTrekTypeService();
export default specialTrekTypeApi;
