/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ReviewService from '../../api/ReviewService';
import './ReviewDashboard.scss';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import AutoCompleteSearchBox from '../common/AutoCompleteSearchBox';
import { Calendar } from 'primereact/calendar';
import {
  IReviewDashboardFilter,
  IReviewDashboardFilterCriteria,
  IReviewDashboardLazyParams,
} from './IReviewDashboardTypes';
import moment from 'moment';
import { ITrekLookupModel } from '../../models/Trek/TrekLookupModel';
import TrekService from '../../api/TrekService';
import lookupApi from '../../api/LookupService';

// @ts-ignore
const ReviewDashboard: React.FC = ({ history, match }) => {
  const { handleSubmit, reset, control, formState } = useForm<IReviewDashboardFilterCriteria>({});
  const [autoTrekValueData, setAutoTrekValueData] = useState<ITrekLookupModel[]>([]);
  const [autoTrekLeaderValueData, setAutoTrekLeaderValueData] = useState<any>([]);

  const [userReviews, setUserReviews] = useState<any[]>();
  const [selectedBatchReviews, setSelectedBatchReviews] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [lazyParams, setLazyParams] = useState<IReviewDashboardLazyParams>({
    first: 0,
    rows: 10,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
  });

  const [reviewDashboardFilter, setReviewDashboardFilter] = useState<IReviewDashboardFilter>({
    trekId: undefined,
    email: undefined,
    batchStartDateFrom: undefined,
    batchStartDateTo: undefined,
    batchEndDateFrom: undefined,
    batchEndDateTo: undefined,
    submittedFrom: undefined,
    submittedTo: undefined,
    trekLeaderUserId: undefined,
    gender: undefined,
  });

  let genderOptionDropdown: any = [
    { name: 'Male', code: 'male' },
    { name: 'Female', code: 'female' },
  ];

  const resetState = () => {
    setLazyParams({ first: 0, rows: 10, page: 0, sortField: 'id', sortOrder: 1 });
  };

  const onPage = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
    loadAllUserReviews(
      _lazyParams.page,
      _lazyParams.rows,
      _lazyParams.sortField,
      _lazyParams.sortOrder == 1,
      reviewDashboardFilter,
    );
  };

  const loadAllUserReviews = async (
    pageNumber: number,
    pageSize: number,
    sortField: string,
    sortOrder: boolean,
    reviewDashboardFilter?: IReviewDashboardFilter,
  ) => {
    setLoading(true);
    await ReviewService.fetchAllUserReviews(pageNumber, pageSize, reviewDashboardFilter, sortField, sortOrder)
      .then((result) => {
        setTotalRecords(Number(result.headers['x-number-of-user-reviews-found']));
        setUserReviews(result.data);
      })
      .catch((error) => {
        setUserReviews([]);
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    loadAllUserReviews(
      lazyParams.page,
      lazyParams.rows,
      lazyParams.sortField,
      lazyParams.sortOrder == 1,
      reviewDashboardFilter,
    );
    const trekService = TrekService;
    trekService.getByLookupFormat().then((trekData) => {
      setAutoTrekValueData(trekData);
    });
    lookupApi.getAllUsersByRole('Trek Leader').then((trekLeaderData) => {
      const trekLeaders = trekLeaderData.map((trekLeader: any) => {
        return { trekLeaderName: trekLeader.displayName, id: trekLeader.id };
      });
      setAutoTrekLeaderValueData(trekLeaders);
    });
  }, []);

  const onSort = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
    loadAllUserReviews(
      lazyParams.page,
      lazyParams.rows,
      _lazyParams.sortField,
      _lazyParams.sortOrder == 1,
      reviewDashboardFilter,
    );
  };

  const batchReviewsTableHeader = (
    <div className="table-header">
      <h4 className="p-m-0">
        <b>Reviews</b>
      </h4>
    </div>
  );

  const bodyTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{data[props.field]}</span>
      </>
    );
  };

  const dateTemplate = (data: any, props: any) => {
    const date = new Date(data[props.field]);
    return (
      <>
        <span className="p-column-title">{date.toLocaleDateString('en-GB')}</span>
      </>
    );
  };

  const actionTemplate = (data: any) => {
    const { batchId, userId, reviewId } = data;
    return (
      <>
        <div className="d-flex align-items-center">
          <div className="p-px-2">
            <Link
              target="_blank"
              to={`/batch_review/${batchId}/userId/${userId}/reviewId/${reviewId}`}
              className="btn btn-sm btn-primary mr-1"
            >
              <i className="pi pi-eye icon-success-color" color="secondary"></i>
            </Link>
          </div>
        </div>
      </>
    );
  };

  const goBack = () => {
    history.push('/');
  };

  const onSubmit = (data: any) => {
    let localReviewDashboardFilter: IReviewDashboardFilter = reviewDashboardFilter;
    localReviewDashboardFilter.trekId = data.trek;

    if (data.selectedUser !== undefined && data.selectedUser.email) {
      localReviewDashboardFilter.email = data.selectedUser.email;
    } else {
      localReviewDashboardFilter.email = undefined;
    }

    localReviewDashboardFilter.batchStartDateFrom =
      data.batchStartDateFrom !== undefined && data.batchStartDateFrom !== null
        ? moment(data.batchStartDateFrom).format('yyyy-MM-DD')
        : undefined;
    localReviewDashboardFilter.batchStartDateTo =
      data.batchStartDateTo !== undefined && data.batchStartDateTo !== null
        ? moment(data.batchStartDateTo).format('yyyy-MM-DD')
        : undefined;

    localReviewDashboardFilter.batchEndDateFrom =
      data.batchEndDateFrom !== undefined && data.batchEndDateFrom !== null
        ? moment(data.batchEndDateFrom).format('yyyy-MM-DD')
        : undefined;
    localReviewDashboardFilter.batchEndDateTo =
      data.batchEndDateTo !== undefined && data.batchEndDateTo !== null
        ? moment(data.batchEndDateTo).format('yyyy-MM-DD')
        : undefined;

    localReviewDashboardFilter.submittedFrom =
      data.submittedFrom !== undefined && data.submittedFrom !== null
        ? moment(data.submittedFrom).format('yyyy-MM-DD')
        : undefined;
    localReviewDashboardFilter.submittedTo =
      data.submittedTo !== undefined && data.submittedTo !== null
        ? moment(data.submittedTo).format('yyyy-MM-DD')
        : undefined;
    localReviewDashboardFilter.gender = data.gender;
    localReviewDashboardFilter.trekLeaderUserId = data.trekLeaderUserId;

    setReviewDashboardFilter(localReviewDashboardFilter);
    resetState();
    loadAllUserReviews(0, 10, 'id', true, localReviewDashboardFilter);
  };

  const userItemTemplate = (item: any) => {
    return (
      <>
        <span className="test">
          {item.firstName} {item.lastName}{' '}
        </span>
        <span>({item.email})</span>
      </>
    );
  };

  return (
    <>
      <div className="table-demo">
        <div>
          <div className="d-flex align-items-center mb-2">
            <div className="flex-grow-1">
              <div className="display-1">Reviews</div>
            </div>
            <div>
              <div>
                <Button label="Back" onClick={goBack} className="p-button-secondary p-button-outlined" />
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
          <div className="card">
            <div className="p-grid">
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="name">Trek Name:</label>
                  </div>
                  <div>
                    <Controller
                      name="trek"
                      control={control}
                      render={({ onChange, value }) => (
                        <Dropdown
                          optionLabel="name"
                          optionValue="trekId"
                          value={value}
                          options={autoTrekValueData}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          placeholder="Select a Trek"
                          filter
                          showClear
                          filterBy="name"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="email">Trek Leader Name:</label>
                  </div>
                  <div>
                    <Controller
                      name="trekLeaderUserId"
                      control={control}
                      render={({ onChange, value }) => (
                        <Dropdown
                          optionLabel="trekLeaderName"
                          optionValue="id"
                          value={value}
                          options={autoTrekLeaderValueData}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          placeholder="Select a Trek Leader"
                          className="g-drp"
                          filter
                          showClear
                          filterBy="trekLeaderName"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="email">Participant Email:</label>
                  </div>
                  <div>
                    <Controller
                      name="selectedUser"
                      control={control}
                      render={({ onChange, value }) => (
                        <AutoCompleteSearchBox
                          value={value}
                          onChange={onChange}
                          displayField="displayName"
                          searchServiceType="CUSTOMER"
                          itemTemplate={userItemTemplate}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="name">Gender:</label>
                  </div>
                  <div>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ onChange, value }) => (
                        <Dropdown
                          optionLabel="name"
                          optionValue="code"
                          value={value}
                          options={genderOptionDropdown}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          placeholder="Select a Gender"
                          className="g-drp"
                          showClear
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="name">Batch start date from:</label>
                  </div>
                  <div>
                    <Controller
                      name="batchStartDateFrom"
                      control={control}
                      render={({ onChange, value }) => (
                        <Calendar
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="2010:2030"
                          value={value}
                          onChange={(e) => onChange(e.value)}
                          showButtonBar
                          showIcon
                        ></Calendar>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="name">Batch start date to:</label>
                  </div>
                  <div>
                    <Controller
                      name="batchStartDateTo"
                      control={control}
                      render={({ onChange, value }) => (
                        <Calendar
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="2010:2030"
                          value={value}
                          onChange={(e) => onChange(e.value)}
                          showButtonBar
                          showIcon
                        ></Calendar>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="name">Batch end date from:</label>
                  </div>
                  <div>
                    <Controller
                      name="batchEndDateFrom"
                      control={control}
                      render={({ onChange, value }) => (
                        <Calendar
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="2010:2030"
                          value={value}
                          onChange={(e) => onChange(e.value)}
                          showButtonBar
                          showIcon
                        ></Calendar>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="name">Batch end date to:</label>
                  </div>
                  <div>
                    <Controller
                      name="batchEndDateTo"
                      control={control}
                      render={({ onChange, value }) => (
                        <Calendar
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="2010:2030"
                          value={value}
                          onChange={(e) => onChange(e.value)}
                          showButtonBar
                          showIcon
                        ></Calendar>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="name">Submitted from:</label>
                  </div>
                  <div>
                    <Controller
                      name="submittedFrom"
                      control={control}
                      render={({ onChange, value }) => (
                        <Calendar
                          dateFormat="dd/mm/yy"
                          value={value}
                          monthNavigator
                          yearNavigator
                          yearRange="2010:2030"
                          onChange={(e) => onChange(e.value)}
                          showButtonBar
                          showIcon
                        ></Calendar>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-3">
                <div>
                  <div className="p-mb-2">
                    <label htmlFor="name">Submitted to:</label>
                  </div>
                  <div>
                    <Controller
                      name="submittedTo"
                      control={control}
                      render={({ onChange, value }) => (
                        <Calendar
                          dateFormat="dd/mm/yy"
                          value={value}
                          monthNavigator
                          yearNavigator
                          yearRange="2010:2030"
                          onChange={(e) => onChange(e.value)}
                          showButtonBar
                          showIcon
                        ></Calendar>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-1"></div>
            <div>
              <Button
                type="submit"
                label="Submit"
                icon="pi pi-filter"
                className="p-mx-2 p-button-lg p-button-secondary"
                disabled={formState.isSubmitting}
              >
                {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
              </Button>
            </div>
          </div>
        </form>

        <div className="card">
          <DataTable
            scrollable
            value={userReviews}
            paginator
            lazy={true}
            first={lazyParams.first}
            rowsPerPageOptions={[10, 20, 50]}
            rows={lazyParams.rows}
            onPage={onPage}
            style={{ width: '100%' }}
            dataKey="id"
            rowHover
            selection={selectedBatchReviews}
            onSelectionChange={(e: any) => setSelectedBatchReviews(e.value)}
            emptyMessage="No record(s) found."
            loading={loading}
            header={batchReviewsTableHeader}
            totalRecords={totalRecords}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
          >
            <Column
              field="batchName"
              header="Trek"
              headerStyle={{ width: '110px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="trekLeader"
              header="Trek Leader"
              headerStyle={{ width: '125px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="batchStartDate"
              header="Batch Start Date"
              headerStyle={{ width: '110px' }}
              sortable
              body={dateTemplate}
            ></Column>
            <Column
              field="batchEndDate"
              header="Batch End Date"
              headerStyle={{ width: '110px' }}
              sortable
              body={dateTemplate}
            ></Column>
            <Column
              field="trekUserName"
              header="Trekker Name"
              headerStyle={{ width: '110px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="userGender"
              header="Trekker Gender"
              headerStyle={{ width: '125px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="trekUserEmail"
              header="Trekker Email"
              headerStyle={{ width: '110px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="trekUserPhone"
              header="Trekker Phone"
              headerStyle={{ width: '125px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="submittedDate"
              header="Submitted Date"
              headerStyle={{ width: '110px' }}
              sortable
              body={dateTemplate}
            ></Column>
            <Column headerStyle={{ width: '2rem', textAlign: 'center' }} body={actionTemplate} header="Action"></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default ReviewDashboard;
