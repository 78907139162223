const DEFAULT_SHIELD_MESSAGE = `Trekking in the Himalayas comes with challenges such as heavy rain, roadblocks, floods, landslides, and permit restrictions imposed by the government. These situations are not within Indiahikes' control. \n
Before you register, please be prepared for the possibility that your trek might be called off due to these uncontrollable factors. In such cases, Indiahikes will make every effort to offer you a safe alternative trek. If that isn't possible, we will cancel your trek and issue a voucher for the trek fee in line with our standard cancellation policy.\n
However, if you would like to receive a full refund of your fee under such circumstances, opt for the Indiahikes Shield while registering. This shield grants you the option to receive a refund in case your trek is cancelled due to external reasons.
`

const KASHMIR_SHIELD_MESSAGE = `Trekking in Kashmir comes with its own uncertainties. There could be several challenges such as political unrest, heavy rain, roadblocks, floods, landslides, and permit restrictions imposed by the government. These situations are not within Indiahikes' control. \n
Before you register, please be prepared for the possibility that your trek might be called off due to these uncontrollable factors. In such cases, Indiahikes will make every effort to offer you a safe alternative trek. If that isn't possible, we will cancel your trek and issue a voucher for the trek fee in line with our standard cancellation policy. \n
However, if you would like to receive a full refund of your fee under such circumstances, opt for the Indiahikes Shield while registering. This shield grants you the option to receive a refund in case your trek is canceled due to external reasons.
`


const MONSOON_SHIELD_MESSAGE = `Trekking in the monsoon season poses several challenges such as heavy rain, roadblocks, floods, landslides, and permit restrictions imposed by the government. These situations are not within Indiahikes' control. \n
Before you register, please be prepared for the possibility that your trek might be called off due to these uncontrollable factors. In such cases, Indiahikes will make every effort to offer you a safe alternative trek. If that isn't possible, we will cancel your trek and issue a voucher for the trek fee in line with our standard cancellation policy. \n
However, if you would like to receive a full refund of your fee under such circumstances, opt for the Indiahikes Shield while registering. This shield grants you the option to receive a refund in case your trek is canceled due to external reasons.` 


export const DIFFERENT_SHIELD_MESSAGE_TYPES = [
    {
        id: "default",
        label: "Default Message",
        description: DEFAULT_SHIELD_MESSAGE
    },
    {
        id: "kashmir",
        label: "Kashmir Message",
        description: KASHMIR_SHIELD_MESSAGE
    },
    {
        id: "monsoon",
        label: "Monsoon Message",
        description: MONSOON_SHIELD_MESSAGE
    },
    {
        id: "none",
        label: "None",
        description: ""
    }
] as const


export const SHIELD_FEE_TYPE_OPTIONS = [
    "ADD_ON", "DASHBOARD"
]


