/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { IReviewModel } from '../../models/index';
import withLoader from '../../helpers/withloader';
import ReviewService from '../../api/ReviewService';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Rating } from 'primereact/rating';
import { Dropdown } from 'primereact/dropdown';

const ReviewPreview: React.FC<RouteComponentProps> = ({ history, match }) => {
  const params: any = match.params;
  const id: number = parseInt(params.id, 10);
  const isAddMode = !id;

  const [reviewData, setReviewData] = useState<IReviewModel>();
  const [loading1, setLoading1] = useState(true);
  const toast = useRef(null);
  const [approvalState, setApprovalState] = useState<string>();
  const [originalState, setOriginalState] = useState<string>();
  const [dropDownStates, setDropdownStates] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  //{ name: 'Require Approval', code: 'PENDING_APPROVAL' },
  const [indexes, setIndexes] = React.useState<number[]>([]);
  const [counter, setCounter] = React.useState(0);

  useEffect(() => {
    const reviewService = ReviewService;
    reviewService.getById(id).then((data) => {
      setReviewData(data);
      setOriginalState(data.reviewState);
      setApprovalState(data.reviewState);
      let dropdownStates: any = [];
      dropdownStates.push({ name: 'Pending Approval', code: 'PENDING_APPROVAL' });
      dropdownStates.push({ name: 'Published', code: 'PUBLISHED' });
      dropdownStates.push({ name: 'Closed', code: 'CLOSED' });
      setDropdownStates(dropdownStates);

      const arr = Array.from(new Array(2), (x, i) => i);
      setIndexes(arr);
      setCounter(1);
      setLoading1(false);
    });
  }, []);

  const onEdit = () => {
    history.push(`/reviews/edit/${id}`);
  };

  const addItineraries = () => {
    setIndexes([...indexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const onApproval = () => {
    const state = approvalState;

    const reviewService = ReviewService;
    reviewService
      .updateReviewState(id, state)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Review state changed Successfully', detail: '' });
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res.response.data.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Status change failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const { control, getValues } = useForm<any>({});

  // @ts-ignore
  return (
    <form>
      <div className="p-grid table-demo">
        <Toast ref={toast}></Toast>
        <div className="p-col-12">
          <div className="d-flex align-items-center mb-2">
            <div className="flex-grow-1">
              <div className="display-1">Reviews-Preview</div>
            </div>
            <div>
              Current Review State:{originalState} &nbsp;&nbsp;&nbsp;
              <Dropdown
                value={approvalState}
                optionValue="code"
                onChange={(e) => {
                  setApprovalState(e.value);
                }}
                options={dropDownStates}
                optionLabel="name"
                placeholder="Select"
              />
            </div>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <div>
              <Button
                label="Change"
                type="button"
                onClick={onApproval}
                className="p-button-warning p-mr-2 p-mb-2 "
                iconPos="right"
              />
            </div>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            {originalState?.toLowerCase() != 'published' && (
              <div>
                <Button
                  label="Edit"
                  onClick={onEdit}
                  className="p-button-lg p-button-secondary "
                  icon="pi pi-arrow-right"
                  iconPos="right"
                />
              </div>
            )}
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <div>
              <Link to="/reviews">
                <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }}></i>
              </Link>
            </div>
          </div>
          {indexes.slice(0, 1).map((i) => {
            {
              return reviewData?.reviewQuestions.map((item, index) => {
                const multiple = item.reviewQuestionType.toLowerCase() == 'multiple_choice';
                const single = item.reviewQuestionType.toLowerCase() == 'single_choice';
                const descriptive = item.reviewQuestionType.toLowerCase() == 'descriptive';
                const rating = item.reviewQuestionType.toLowerCase() == 'rating';

                return (
                  <div className="card">
                    <strong>
                      <span dangerouslySetInnerHTML={{ __html: item.question }} />
                    </strong>
                    {multiple && (
                      <div>
                        {Array.isArray(item.answers) &&
                          item.answers.map((ch, index) => {
                            return (
                              <div>
                                <p></p>
                                <Controller
                                  name={`${item.questionId}-${index}`}
                                  control={control}
                                  render={({ onChange, value }) => (
                                    <Checkbox
                                      checked={value}
                                      onChange={(e) => {
                                        onChange(e.checked);
                                      }}
                                    />
                                  )}
                                />
                                <label className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{ch.optionText}</label>
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {single && (
                      <div>
                        {Array.isArray(item.answers) &&
                          item.answers.map((ch, index) => {
                            // @ts-ignore
                            const formValues: string = getValues(item.questionId.toString());
                            // @ts-ignore
                            let radioChecked: boolean = false;
                            if (formValues !== undefined) {
                              const val = formValues.split('-');
                              if (Number(val[1]) === index) radioChecked = true;
                            }
                            return (
                              <div className="p-field-radiobutton">
                                <p></p>
                                <Controller
                                  name={`${item.questionId}`}
                                  control={control}
                                  render={({ onChange, value }) => (
                                    <RadioButton
                                      name={`${item.questionId}`}
                                      onChange={(e) => {
                                        onChange(`${item.questionId}-${index}`);
                                        addItineraries();
                                      }}
                                      checked={radioChecked}
                                    />
                                  )}
                                />

                                <label className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{ch.optionText}</label>
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {descriptive && (
                      <div>
                        <p></p>
                        <InputText className="p-my-2 w-100" />
                      </div>
                    )}
                    {rating && (
                      <div>
                        <p></p>
                        <Controller
                          name={`${item.questionId}`}
                          control={control}
                          render={({ onChange, value }) => (
                            <Rating
                              stars={5}
                              className="p-rating-star"
                              value={value}
                              onChange={(e) => {
                                onChange(e.value);
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                );
              });
            }
          })}
        </div>
      </div>
    </form>
  );
};
export default withLoader(ReviewPreview);
