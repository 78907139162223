/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import withLoader from '../../helpers/withloader';
import { ITrekLookupModel } from '../../models/index';
import { AutoComplete } from 'primereact/autocomplete';
import TrekService from '../../api/TrekService';
import LookupService from '../../api/LookupService';
import { ITrekSeasons } from '../../models/Trek/TrekSeasons';
import BatchService from '../../api/BatchService';
import { IBatchModel } from '../../models/Batch/BatchFormModel';
import { Dropdown } from 'primereact/dropdown';

const MultiBatch: React.FC<RouteComponentProps> = ({ history, match }) => {
  const params: any = match.params;
  const id: number = parseInt(params.id, 10);
  const toast = useRef(null);

  const [autoTrekValueData, setAutoTrekValueData] = useState<ITrekLookupModel[]>([]);
  const [selectedTrekValue, setSelectedTrekValue] = useState<ITrekLookupModel | null>(null);
  const [autoFilteredValue, setAutoFilteredValue] = useState<ITrekLookupModel[]>([]);

  const [autoSeasonsValueData, setAutoSeasonsValueData] = useState<ITrekSeasons[]>([]);
  const [autoSeasonsFilteredValue, setSeasonsFilteredValue] = useState<ITrekSeasons[]>([]);

  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null);

  const dayOfWeeks = [
    { name: 'Sunday', code: 'Sunday' },
    { name: 'Monday', code: 'Monday' },
    { name: 'Tuesday', code: 'Tuesday' },
    { name: 'Wednesday', code: 'Wednesday' },
    { name: 'Thursday', code: 'Thursday' },
    { name: 'Friday', code: 'Friday' },
    { name: 'Saturday', code: 'Saturday' },
  ];

  const onDayOfWeekChange = (e: { value: React.SetStateAction<null> }) => {
    setSelectedDayOfWeek(e.value);
  };

  const defaultValues = {
    privateValue: false,
    multiBatchEnabled: false,
    waitCapacity: 0,
    capacity: 0,
    dayOfWeekName: '',
  };

  useEffect(() => {
    const trekService = TrekService;
    const lookupService = LookupService;
    trekService.getByLookupFormat().then((trekData) => {
      setAutoTrekValueData(trekData);
    });
  }, []);

  const autoSearchTrek = (event: any) => {
    if (!event.query.trim().length) {
      setAutoFilteredValue([...autoTrekValueData]);
    } else {
      let avValue: any = autoTrekValueData;
      setAutoFilteredValue(
        avValue.filter((location: any) => {
          return location.name.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchSeasons = (event: any) => {
    if (!event.query.trim().length) {
      setSeasonsFilteredValue([...autoSeasonsValueData]);
    } else {
      setSeasonsFilteredValue(
        autoSeasonsValueData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  // @ts-ignore
  const validationSchema = useMemo(
    () =>
      Yup.object({
        trekId: Yup.number().required('Trek is required'),
        seasonId: Yup.object().required('Season is required'),
        startDate: Yup.date().required('Start Date  is required'),
        endDate: Yup.date()
          .required('End Date  is required')
          .test('enddate-comparer', 'End date should greater than start-date', function (value: any) {
            const startDt: Date = new Date(getValues('startDate'));
            const endDt: Date = new Date(value);
            const valid: boolean = endDt > startDt;
            return valid;
          }),
      }),
    [],
  );

  const { register, handleSubmit, reset, setValue, control, getValues, errors, formState } = useForm<IBatchModel>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: defaultValues,
  });

  const onSubmit = (data: IBatchModel) => {
    createBatch(data);
  };

  const createBatch = (data: IBatchModel) => {
    data.multiBatchEnabled = true;
    return BatchService.create(data)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Season Created Successfully', detail: '' });
        //setInput1Focus
        // alertService.success('User added', { keepAfterRouteChange: true });
        //reset();
        history.push('/batches');
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res?.response?.data?.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Creation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const validationSummary = (
    <div className="validation-summary-error">
      <pre>
        {Object.keys(errors).length > 0 && (
          <div>
            Please fill missing/correct required field values in the following information :
            <ul>
              {Object.keys(errors).map((field) => (
                <li>{field} </li>
              ))}
            </ul>
          </div>
        )}
      </pre>
    </div>
  );

  const handleCancelClick = () => {
    history.push('/batches');
  };

  const bindSeasons = (trekId: number, flag: number = 1, seasonId?: number) => {
    setValue('seasonId', null);
    setSeasonsFilteredValue([]);
    setAutoSeasonsValueData([]);
    if (trekId > 0) {
      const lookupService = LookupService;
      lookupService.getTrekSeasons(trekId).then((seasonsData) => {
        const dt: ITrekSeasons[] = [];
        seasonsData.map((x) => {
          dt.push({
            id: x.id,
            formattedText: formattedDateText(x.startDate, x.endDate).toString(),
            trekId: x.trekId,
            startDate: x.startDate,
            endDate: x.endDate,
            trek: x.trek,
          });
        });
        setAutoSeasonsValueData(dt);
        if (flag == 2) {
          const selectedSeason = dt.find((x) => x.id == seasonId);
          setValue('seasonId', selectedSeason);
        }
      });
    }
  };

  // @ts-ignore
  const formatYmd = (dt: Date) => dt.getDate() + '-' + months[dt.getMonth()] + '-' + dt.getFullYear();
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  const formattedDateText = (startDate: Date, endDate: Date) => {
    const date = new Date(startDate);
    const currentDayOfMonth = date.getDate();
    const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
    const currentYear = date.getFullYear();

    const dateString = currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear;
    return `${formatYmd(new Date(startDate))} - ${formatYmd(new Date(endDate))}`;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
        <Toast ref={toast}></Toast>
        <div className="d-flex align-items-center details-title">
          <div className="flex-grow-1">
            <div className="display-1">{'Add Multi Groups'}</div>
          </div>
          <div>
            <Link to="/batches">
              <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }}></i>
            </Link>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12 p-lg-8 p-md-12">
            <div className="card p-fluid">
              <h4>Group details</h4>
              <div className="p-grid">
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-3">
                      <div className="p-mb-2 required">
                        <label htmlFor="trekId">Trek:</label>
                      </div>
                      <div>
                        <Controller
                          name="trekId"
                          control={control}
                          render={({ onChange, value }) => (
                            <AutoComplete
                              placeholder="Search"
                              autoFocus
                              dropdown
                              forceSelection
                              value={selectedTrekValue}
                              onChange={(e) => {
                                setSelectedTrekValue(e.value);
                                bindSeasons(e.value?.trekId);
                                onChange(e.value?.trekId);
                              }}
                              suggestions={autoFilteredValue}
                              completeMethod={autoSearchTrek}
                              field="name"
                            ></AutoComplete>
                          )}
                        />
                        {errors.trekId && (
                          <span className="p-error">
                            <p>Error:{errors.trekId?.message}</p>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-12 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="seasonId">Seasons:</label>
                          </div>
                          <div>
                            <Controller
                              name="seasonId"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder="Search"
                                  dropdown
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                  suggestions={autoSeasonsFilteredValue}
                                  completeMethod={autoSearchSeasons}
                                  field="formattedText"
                                ></AutoComplete>
                              )}
                            />
                            {errors.seasonId && (
                              <span className="p-error">
                                <p>Error:Season required</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-lg-6 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="name">Start Date:</label>
                          </div>
                          <div>
                            <Controller
                              name="startDate"
                              control={control}
                              render={({ onChange, value }) => (
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  value={value}
                                  onChange={(e) => onChange(e.value)}
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2010:2030"
                                ></Calendar>
                              )}
                            />
                            {errors.startDate && (
                              <span className="p-error">
                                <p>Error:{errors.startDate?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-lg-6 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="endDate">End Date:</label>
                          </div>
                          <div>
                            <Controller
                              name="endDate"
                              control={control}
                              render={({ onChange, value }) => (
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  value={value}
                                  onChange={(e) => onChange(e.value)}
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2010:2030"
                                ></Calendar>
                              )}
                            />
                            {errors.endDate && (
                              <span className="p-error">
                                <p>Error:{errors.endDate?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="p-col-12 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="dayOfWeekName">Day of week:</label>
                          </div>
                          <div>
                            <Dropdown
                              value={selectedDayOfWeek}
                              options={dayOfWeeks}
                              onChange={onDayOfWeekChange}
                              optionLabel="name"
                              placeholder="Select a Day of Week"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex p-mt-2">
                <div className="flex-grow-1"></div>
                <div className="p-mx-2">
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-warning p-button-lg"
                    onClick={handleCancelClick}
                  />
                </div>
                <div className="">
                  <Button
                    type="submit"
                    label="Submit"
                    icon="pi pi-check"
                    className="p-button-lg p-button-secondary"
                    disabled={formState.isSubmitting}
                  >
                    {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withLoader(withRouter(MultiBatch));
