import { join, pickBy } from 'lodash';
import axios from 'axios';
import { DataTableSortOrderType } from 'primereact/datatable';
import {
  BookingState,
  IbookingsParticipantsList,
  IParticipantsFilter,
  BookingParticipantState,
} from '../models/Booking/BookingParticipantsList';
import { getHeaderWithBearerToken } from '../helpers';
import { IbackpackOffloadingList } from '../models/Booking/BackPackOffloadingModel';

export const participantBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/participants`;
export const usersMyBookingAPI = `${process.env.REACT_APP_TMS_BACKEND_URL}/users/my-bookings`;

class BookingParticipantService {
  get = (
    pageNo: number,
    pageSize: number,
    participantFilters?: IParticipantsFilter,
    sortField?: string,
    sortOrderDescending?: boolean,
  ) => {
    return axios.get<IbookingsParticipantsList[]>(participantBaseApi, {
      headers: getHeaderWithBearerToken(),
      params: {
        pageNo,
        pageSize,
        sortField,
        sortOrderDescending,
        ...pickBy(participantFilters, (val) => {
          return val !== undefined;
        }),
      },
    });
  };

  downloadParticipant = (
    participantsFilter?: IParticipantsFilter,
    sortField?: string,
    sortOrder?: number | DataTableSortOrderType,
  ) => {
    let url = `${participantBaseApi}/downloads`;
    if (sortField) url += `?sortField=${sortField}&sortOrderDescending=${sortOrder !== -1}`;

    if (participantsFilter === undefined) {
      const today = new Date();
      today.setUTCHours(0, 0, 0);
      const newFilter: IParticipantsFilter = { batchStartDateFrom: `${today.toISOString().split('T')[0]}` };
      return axios
        .get(url, {
          headers: getHeaderWithBearerToken(),
          responseType: 'blob',
          params: {
            ...pickBy(newFilter, (val) => {
              return val !== undefined;
            }),
          },
        })
        .then((res) => res.data);
    }
    return axios.get(url, {
      headers: getHeaderWithBearerToken(),
      responseType: 'blob',
      params: {
        ...pickBy(participantsFilter, (val) => {
          return val !== undefined;
        }),
      },
    });
  };

  fetchBackpackCSV = (searchQuery?: string, sortField?: string, sortOrder?: number | DataTableSortOrderType) => {
    return axios.get(`${participantBaseApi}/backpack-offloading/downloads`, {
      headers: getHeaderWithBearerToken(),
      responseType: 'blob',
      params: {
        searchQuery,
        sortField,
        sortOrderDescending: sortOrder !== -1,
      },
    });
  };

  getBackPacks = (
    pageNo: number,
    pageSize: number,
    searchQuery?: string,
    sortField?: string,
    sortOrderDescending?: boolean,
  ) => {
    return axios
      .get<IbackpackOffloadingList[]>(`${participantBaseApi}/backpacks`, {
        headers: getHeaderWithBearerToken(),
        params: {
          pageNo,
          pageSize,
          searchQuery,
          sortField,
          sortOrderDescending,
        },
      })
      .then((res) => res);
  };

  getCompletedBookingByBatchId = (pageNo: number, pageSize: number, batchId: number) => {
    const url = `${participantBaseApi}?pageNo=${pageNo}&pageSize=${pageSize}&bookingState=${BookingState.COMPLETED}&participantState=${BookingParticipantState.APPROVED},${BookingParticipantState.PENDING_APPROVAL},${BookingParticipantState.FITNESS_REJECTED}&batchId=${batchId}`;
    return axios
      .get<IbookingsParticipantsList[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getByBatchId = (pageNo: number, pageSize: number, batchId: number) => {
    const url = `${participantBaseApi}?pageNo=${pageNo}&pageSize=${pageSize}&batchId=${batchId}&bookingState=${BookingState.COMPLETED},${BookingState.ADD_PARTICIPANTS},${BookingState.CANCELLED},${BookingState.DIFFICULT_TREK},${BookingState.INACTIVE},${BookingState.PAYMENT},${BookingState.WAITING_LIST}`;
    return axios
      .get<IbookingsParticipantsList[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getParticipantForApproval = (pageNo: number, pageSize: number, batchId: number) => {
    const url = `${participantBaseApi}?pageNo=${pageNo}&pageSize=${pageSize}&bookingState=${BookingState.COMPLETED}&batchId=${batchId}&participantState=${BookingParticipantState.APPROVED},${BookingParticipantState.PENDING_APPROVAL},${BookingParticipantState.FITNESS_REJECTED},${BookingParticipantState.WAITING_LIST}`;
    return axios
      .get<IbookingsParticipantsList[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getParticipantForApprovalDashboard = (pageNo: number, pageSize: number, participantsFilter?: IParticipantsFilter) => {
    const url = `${participantBaseApi}`;
    let participantStatesListString = null;
    if (participantsFilter?.participantStatesList) {
      participantStatesListString = join(participantsFilter.participantStatesList, ',');
    }
    return axios
      .get<IbookingsParticipantsList[]>(url, {
        headers: getHeaderWithBearerToken(),
        params: {
          pageNo,
          pageSize,
          ...pickBy(participantsFilter, (val) => {
            return val !== undefined;
          }),
          participantStatesList: participantStatesListString,
        },
      })
      .then((res) => res);
  };

  getById = (batchId: number) => {
    const url = `${participantBaseApi}`;
    return axios
      .get<IbookingsParticipantsList>(url, {
        params: {
          id: batchId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  approveBooking = (participantId: number) => {
    const url = `${participantBaseApi}/${participantId}/approvals`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  disapproveBooking = (participantId: number, commentText: string) => {
    const url = `${participantBaseApi}/${participantId}/disapproval`;
    return axios.patch(url, { commentText }, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  sendFitnessReminder = (participantId: number, commentText: string) => {
    const url = `${participantBaseApi}/${participantId}/fitness-reminder`;
    return axios.patch(url, { commentText }, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  cancelParticipantBackpackOffloading = async (bookingId: number, participantId: number) => {
    const url = `${participantBaseApi}/bookings/${bookingId}/cancel-backpack-for-the-participants?refund=true&backPackOffloading=true`;
    const res = await axios.patch(url, [participantId], { headers: getHeaderWithBearerToken() });
    return res.data;
  };
}

const bookingParticipantApi = new BookingParticipantService();
export default bookingParticipantApi; // exporting like a singleton
