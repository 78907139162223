/* eslint-disable */
import React, { useEffect, useState } from 'react';

type ComponentProperties = {
  text: string;
  icon?: string;
  iconSpin?: boolean;
};

const Overlay: React.FC<ComponentProperties> = (props) => {
  const [iconClass, setIconClass] = useState<string>('');
  const [iconSpin, setIconSpin] = useState<string>('');

  useEffect(() => {
    if (props.icon) {
      setIconClass(props.icon);
    }
    if (props.iconSpin) {
      setIconSpin('pi-spin');
    }
  }, [props]);

  return (
    <div className="loader-wrap">
      <div className="loader-circle">
        <i className={`pi ${iconSpin} ${iconClass}`} />
        <h3>{props.text}</h3>
      </div>
    </div>
  );
};

export default Overlay;
