import Keycloak from 'keycloak-js';

const initOptions = {
  url: `${process.env.REACT_APP_IAM_URL}/auth/`,
  realm: 'IndiaHikes',
  clientId: 'tms-ui',
};

const keycloakContext = new Keycloak(initOptions);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {
  keycloakContext.init({ onLoad: 'login-required' }).then((authenticated) => {
    // if (authenticated) {
    onAuthenticatedCallback();
    // } else {
    //   doLogin();
    // }
  });
};

localStorage.setItem('react-token', keycloakContext.token);
localStorage.setItem('react-refresh-token', keycloakContext.refreshToken);

const doLogin = keycloakContext.login;

const doLogout = keycloakContext.logout;

const getToken = () => keycloakContext.token;

const isLoggedIn = () => !!keycloakContext.token;

const updateToken = (successCallback) => keycloakContext.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => keycloakContext.tokenParsed?.preferred_username;

const getName = () => `${keycloakContext.tokenParsed?.given_name} ${keycloakContext.tokenParsed?.family_name}`;

const hasRole = (roleName) => keycloakContext.hasRealmRole(roleName);

const hasBackofficeRole = () => keycloakContext.hasRealmRole('SUPER_ADMIN');

const getEmail = () => keycloakContext.tokenParsed?.email;

const tokenExpired = () => keycloakContext.isTokenExpired();

const refreshToken = () => {
  keycloakContext
    .updateToken(70)
    .then((refreshed) => {
      if (refreshed) {
        console.log(`Token refreshed ${refreshed}`);
      } else if (keycloakContext.tokenParsed && keycloakContext.tokenParsed.exp && keycloakContext.timeSkew) {
        console.warn(
          `Token not refreshed, valid for ${Math.round(
            keycloakContext.tokenParsed.exp + keycloakContext.timeSkew - new Date().getTime() / 1000,
          )} seconds`,
        );
      }
    })
    .catch(() => {
      console.error('Failed to refresh token');
    });
};

const UserService = {
  refreshToken,
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  hasBackofficeRole,
  getName,
  getEmail,
  tokenExpired,
};

export default UserService;
