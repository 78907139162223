/* eslint-disable */
import React, { useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from 'react-hook-form';
import { IFitnessAprovalDashboardFilterCriteria } from '../Bookings/Fitness-Approval/IFitnessAprovalDashboardFilterCriteria';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import BookingParticipantService from '../../api/BookingParticipantService';
import { IFitnessActionModel } from '../../models/Booking/ParticipantDisapprovalModel';

export enum ParticipantActionType {
  Disapprove = 'Disapprove',
  Reminder = 'Reminder',
}

const ActionAttributes = {
  disapprove: {
    type: ParticipantActionType.Disapprove,
    callback: BookingParticipantService.disapproveBooking,
    heading: 'Disapprove Participant',
    successMessage: 'Participant Disapproved Successfully',
    errorFieldMessage: "Disapproval reason can't be empty",
    textAreaLabel: 'Reason for Disapproval',
    buttonLabel: 'Disapprove',
    buttonIcon: 'pi-exclamation-triangle',
    buttonClass: 'p-button-danger',
  },
  reminder: {
    type: ParticipantActionType.Reminder,
    callback: BookingParticipantService.sendFitnessReminder,
    heading: 'Send Notification',
    successMessage: 'Reminder Notification Sent Successfully',
    errorFieldMessage: "Reminder text can't be empty",
    textAreaLabel: 'Reminder Text',
    buttonLabel: 'Send Notification',
    buttonIcon: 'pi-bell',
    buttonClass: 'p-button-info',
  },
};

const getActionAttribute = (participantActionType: ParticipantActionType) => {
  switch (participantActionType) {
    case ParticipantActionType.Reminder:
      return ActionAttributes.reminder;
    default:
      return ActionAttributes.disapprove;
  }
};

type ComponentProperties = {
  actionType: ParticipantActionType;
  displayModal: boolean;
  onHide: () => void;
  isSubmitting: boolean;
  selectedParticipantId: number | undefined;
  postCallback: () => void;
  setLoader: (state: boolean) => void;
};

const ParticipantActionDialog: React.FC<ComponentProperties> = (props) => {
  const { actionType, displayModal, onHide, isSubmitting, selectedParticipantId, postCallback, setLoader } = props;
  const { handleSubmit, control } = useForm<IFitnessAprovalDashboardFilterCriteria>({});
  const toast = useRef(null);

  const {
    callback,
    heading,
    successMessage,
    errorFieldMessage,
    textAreaLabel,
    buttonLabel,
    buttonIcon,
    buttonClass,
  } = getActionAttribute(actionType);

  const onParticipantAction = (data: IFitnessActionModel) => {
    const tst: any = toast?.current;
    if (!data.commentText) {
      tst.show({
        severity: 'error',
        summary: errorFieldMessage,
      });
      return;
    }
    if (!selectedParticipantId) {
      tst.show({
        severity: 'error',
        summary: "Participant Id can't be empty",
      });
      return;
    }
    setLoader(true);

    callback(selectedParticipantId, data.commentText)
      .then(() => {
        postCallback();
        tst.show({ severity: 'success', summary: successMessage });
      })
      .catch((res) => {
        if (res.response.data.message) tst.show({ severity: 'error', summary: `${res.response.data.message}` });
        else
          tst.show({
            severity: 'error',
            summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
          });
      })
      .finally(() => {
        setLoader(false);
        onHide();
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={heading} visible={displayModal} style={{ width: '50vw' }} onHide={onHide}>
        <form onSubmit={handleSubmit(onParticipantAction)}>
          <div className="p-mb-2">
            <label htmlFor="name">{textAreaLabel}</label>
          </div>
          <div>
            <Controller
              name="commentText"
              control={control}
              defaultValue={undefined}
              render={({ onChange, value }) => (
                <InputTextarea value={value} onChange={(e) => onChange(e.target.value)} rows={10} />
              )}
            />
          </div>
          <div className="d-flex p-mt-4">
            <div className="flex-grow-1"></div>
            <div>
              <Button
                type="submit"
                label={buttonLabel}
                icon={`pi ${buttonIcon}`}
                className={`p-mx-2 p-button-lg ${buttonClass}`}
                disabled={isSubmitting}
              >
                {isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default ParticipantActionDialog;
