import axios from 'axios';
import { pickBy } from 'lodash';
import {
  IQuestionUIModel,
  IReviewListModel,
  IReviewQuestionModel,
  IReviewModel,
  RefundByTrekviewListModel,
  IReplyEmailData,
} from '../models/index';
import { IReviewUIModel } from '../models/Reviews/ReviewUIModel';
import { getHeaderWithBearerToken } from '../helpers';

export const reviewBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/reviews`;

export const RefundByTrekviewListApi = `./mock-data/refund-by-trek.json`;

class ReviewService {
  getAxiosInstance = () => {
    return axios;
  };

  get = () => {
    const url = `${reviewBaseApi}`;
    return axios
      .get<IReviewListModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getProductsSmall = () => {
    const url = `${RefundByTrekviewListApi}`;
    return axios
      .get<RefundByTrekviewListModel[]>('./data/refund-by-trek.json', { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getByIdUrl = (reviewId: number): string => {
    return `${reviewBaseApi}/${reviewId}`;
  };

  getById = (reviewId: number) => {
    const url = `${reviewBaseApi}/${reviewId}`;
    return axios
      .get<IReviewModel>(url, {
        params: {
          id: reviewId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  create = (data: IReviewUIModel) => {
    const apiModel = {
      title: data.title,
      description: data.description,
      reviewQuestions: this.buildReviewQuestions(data),
      reviewState: 'PENDING_APPROVAL',
      trekIds: data?.treks?.map((item) => {
        const x = item.trekId;
        return x;
      }),
    };
    const url = `${reviewBaseApi}`;
    return axios
      .post<IReviewModel>(url, apiModel, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  buildReviewQuestions = (data: IReviewUIModel): IReviewQuestionModel[] => {
    // SINGLE_CHOICE, DESCRIPTIVE, RATING, MULTIPLE_CHOICE
    const questions: IReviewQuestionModel[] = [];

    if (data.multiple !== undefined && data.multiple.length > 0)
      this.buildQuestionObject(questions, data.multiple, 'MULTIPLE_CHOICE');

    if (data.single !== undefined && data.single.length > 0)
      this.buildQuestionObject(questions, data.single, 'SINGLE_CHOICE');

    if (data.descriptive !== undefined && data.descriptive.length > 0)
      this.buildQuestionObject(questions, data.descriptive, 'DESCRIPTIVE');

    if (data.rating !== undefined && data.rating.length > 0) this.buildQuestionObject(questions, data.rating, 'RATING');

    return questions;
  };

  buildQuestionObject = (coll: IReviewQuestionModel[], questionModel: IQuestionUIModel[] | undefined, type: string) => {
    questionModel?.forEach((item) => {
      coll.push({
        questionId: 0,
        reviewQuestionType: type,
        question: item.question,
        position: item.position,
        tags: this.buildTags(item.tags),
        answers: item.answers,
      });
    });
  };

  buildTags = (tags: any) => {
    const dt: number[] = [];
    if (Array.isArray(tags)) {
      tags.forEach((tg) => {
        dt.push(tg.id);
      });
    }
    return dt;
  };

  update = (reviewId: number, data: IReviewUIModel) => {
    const url = `${reviewBaseApi}`;
    const apiModel = {
      id: reviewId,
      title: data.title,
      description: data.description,
      reviewQuestions: this.buildReviewQuestions(data),
      reviewState: 'PENDING_APPROVAL',
      trekIds: data?.treks?.map((item) => {
        const x = item.trekId;
        return x;
      }),
    };

    return axios.put(url, apiModel, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  updateReviewState = (id: number, reviewState: string | undefined) => {
    const url = `${reviewBaseApi}/${id}/review-states/${reviewState}`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() });
  };

  deleteReview = (id: number) => {
    const url = `${reviewBaseApi}/${id}`;
    return axios.delete(url, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  fetchAllUserReviewByBatch = (batchId: number) => {
    return axios.get(`${reviewBaseApi}/batch/${batchId}/user-reviews/`, {
      headers: getHeaderWithBearerToken(),
    });
  };

  fetchAllUserReviews = (
    pageNumber: number,
    pageSize: number,
    reviewDashboardFilter: any,
    sortField: string,
    sortOrderDescending: boolean,
  ) => {
    return axios.get(`${reviewBaseApi}/user-reviews`, {
      headers: getHeaderWithBearerToken(),
      params: {
        pageNumber,
        pageSize,
        sortField,
        sortOrderDescending,
        ...pickBy(reviewDashboardFilter, (val) => {
          return val !== undefined;
        }),
      },
    });
  };

  fetchIndividualReview = ({ batchId, userId, reviewId }: { batchId: string; userId: string; reviewId: string }) => {
    return axios.get(`${reviewBaseApi}/batch/${batchId}/user/${userId}/review/${reviewId}`, {
      headers: getHeaderWithBearerToken(),
    });
  };

  publishUserReviews = (answerIds: number[]) => {
    return axios.patch(`${reviewBaseApi}/publish/user-review`, answerIds, {
      headers: getHeaderWithBearerToken(),
    });
  };

  unpublishUserReviews = (answerIds: number[]) => {
    return axios.patch(`${reviewBaseApi}/unpublish/user-review`, answerIds, {
      headers: getHeaderWithBearerToken(),
    });
  };

  replyToReview = (emailContent: IReplyEmailData) => {
    return axios.post(`${reviewBaseApi}/reply/email`, emailContent, { headers: getHeaderWithBearerToken() });
  };
}

const reviewApi = new ReviewService();
export default reviewApi; // exporting like a singleton
