import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import './Normal_view.scss';
import { Column, ColumnProps } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import BookingParticipantService from '../../../api/BookingParticipantService';
import {
  BookingParticipantState,
  BookingState,
  IbookingsParticipantsList,
  IParticipantsFilter,
} from '../../../models/Booking/BookingParticipantsList';
import { getFlagTextColor } from '../../User/UserFlags/helper';
import batchServiceApi from '../../../api/BatchService';
import { UserTravelModel } from '../../../models/User/UserTravelModel';
import { TravelRouteType, UserTravelState } from '../../Seasons/SeasonConstants';

const PREFERENCE_STORAGE_KEY = 'confirmedViewPreferences';

type ComponentProperties = {
  mode?: boolean;
  id?: number;
  history?: any;
};

interface ColumnFieldDataProps extends ColumnProps {
  field: keyof IbookingsParticipantsList;
}

export type ViewPreference = {
  displayOnlyPaidTravels: boolean;
};

const DEFAULT_PREFERENCE: ViewPreference = {
  displayOnlyPaidTravels: true,
};

const BatchConfirmedViewComponent: React.FC<ComponentProperties> = (props) => {
  const { id } = props;
  const toast = useRef(null);
  const dt = useRef<DataTable>(null);

  const [preferences, setPreferences] = useState<ViewPreference>(DEFAULT_PREFERENCE);
  const [participants, setParticipants] = useState<IbookingsParticipantsList[]>();
  const [selectedParticipants, setSelectedParticipants] = useState<IbookingsParticipantsList>();
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [displayOnlyPaidTravels, setDisplayOnlyPaidTravels] = useState(true);

  // calculate age
  const calculateAge = (birthday: string | number | Date) => {
    // milliseconds in a year 1000*24*60*60*365.24 = 31556736000;
    const today = new Date();
    // birthay has 'Dec 25 1998'
    const dob = new Date(birthday);
    // difference in milliseconds
    const diff = today.getTime() - dob.getTime();
    // convert milliseconds into years
    const years = Math.floor(diff / 31556736000);
    // console.log(`${years} years ${months} months ${days} days`);
    return `${years} years`;
  };

  const getLocationDataForParticipant = (userTravelData: UserTravelModel[], participantId: number) => {
    const getLocationData = (routeType: TravelRouteType) => {
      const data = userTravelData.find(
        (userTravel) => userTravel.participantId === participantId && userTravel.travelRouteType === routeType,
      );
      return data ? { location: data.travelRouteText, status: data.state } : { location: null, status: null };
    };

    const { location: pickUpLocation, status: pickUpStatus } = getLocationData(TravelRouteType.PICKUP);
    const { location: dropOffLocation, status: dropOffStatus } = getLocationData(TravelRouteType.DROP_OFF);

    return { pickUpLocation, pickUpStatus, dropOffLocation, dropOffStatus };
  };

  const fetchAndProcessData = useCallback(async () => {
    if (!id) return;

    const participantApiData = await BookingParticipantService.getCompletedBookingByBatchId(0, 1000, id);
    const travelData = await batchServiceApi.getUserTravelsData(id);

    const participantsData = participantApiData.map((part) => {
      const { pickUpLocation, pickUpStatus, dropOffLocation, dropOffStatus } = getLocationDataForParticipant(
        travelData,
        part.id,
      );

      return {
        ...part,
        firstName: part.userDetailsForDisplay.firstName,
        userReferenceId: part.userDetailsForDisplay.userReferenceId,
        lastName: part.userDetailsForDisplay.lastName,
        height: part.userDetailsForDisplay.height,
        weight: part.userDetailsForDisplay.weight,
        bmi: part.userDetailsForDisplay.bmi,
        phone: part.userDetailsForDisplay.phone,
        email: part.userDetailsForDisplay.email,
        dob: part.userDetailsForDisplay.dob,
        gender: part.userDetailsForDisplay.gender,
        city: part.userDetailsForDisplay.city,
        emergencyContactNumber: part.userDetailsForDisplay.emergencyContactNumber,
        age: part.userDetailsForDisplay.dob ? calculateAge(part.userDetailsForDisplay.dob) : '',
        userComment: part.userDetailsForDisplay.comment?.commentText,
        pickupLocation: pickUpLocation,
        pickupStatus: pickUpStatus,
        dropOffLocation,
        dropOffStatus,
      };
    });

    setParticipants(participantsData);
    setLoading(false);
  }, [id]);

  // after participant data is fetched,
  // fetch the individual trekker completed treks and set it in state
  useEffect(() => {
    if (participants && !participants?.[0]?.completedTrekList) {
      fetchTreksForAllParticipants(BookingParticipantService, participants, setParticipants);
    }
  }, [participants]);

  useEffect(() => {
    const storedPreferences = localStorage.getItem(PREFERENCE_STORAGE_KEY);
    if (storedPreferences) {
      const preferencesObject = JSON.parse(storedPreferences) as ViewPreference;
      setPreferences(preferencesObject);
      setDisplayOnlyPaidTravels(preferencesObject.displayOnlyPaidTravels);
    }
    fetchAndProcessData();
  }, [fetchAndProcessData]);

  useEffect(() => {
    localStorage.setItem(PREFERENCE_STORAGE_KEY, JSON.stringify(preferences));
  }, [preferences]);

  const updatePreferences = (newPreferences: any) => {
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      ...newPreferences,
    }));
  };

  const bodyTemplate = (data: IbookingsParticipantsList, bodyProps: ColumnFieldDataProps) => {
    const textColor = getFlagTextColor(data.userDetailsForDisplay, bodyProps);
    return (
      <span style={{ color: textColor }} className="p-column-title">
        {data[bodyProps.field]}
      </span>
    );
  };

  const flagTemplate = (data: IbookingsParticipantsList, flagProps: ColumnFieldDataProps) => {
    const textColor = getFlagTextColor(data.userDetailsForDisplay, flagProps);
    return (
      <span style={{ color: textColor }} className="p-column-title">
        {data?.userDetailsForDisplay?.flag?.title}
      </span>
    );
  };

  const completedTrekListBodyTemplate = (data: IbookingsParticipantsList) => {
    const completedTrekList = data?.completedTrekList;

    if (completedTrekList?.length) return completedTrekList?.join(', ');

    // if no list, return "loading"
    return "loading..."
    
  };

  const locationTemplate = (data: IbookingsParticipantsList, locationProps: ColumnFieldDataProps) => {
    const locationField = locationProps.field;

    let status: UserTravelState | null;
    switch (locationField) {
      case 'pickupLocation':
        status = data.pickupStatus;
        break;
      case 'dropOffLocation':
        status = data.dropOffStatus;
        break;
      default:
        status = null;
    }

    let isPaidTravel = false;
    let iconColor = '';
    let iconClass = '';

    switch (status) {
      case UserTravelState.PAID:
        isPaidTravel = true;
        iconColor = 'icon-success-color';
        iconClass = 'pi-check-circle';
        break;
      case UserTravelState.CANCELLED:
        iconColor = 'icon-warn-color';
        iconClass = 'pi-times-circle';
        break;
      case UserTravelState.INITIATED:
        iconColor = 'icon-light-color';
        iconClass = 'pi-sun';
        break;
      default:
        iconColor = '';
        iconClass = '';
    }

    const travelStatusId = `travel_${data.id}_${locationField}`;

    if (displayOnlyPaidTravels && !isPaidTravel) {
      return <></>;
    }

    return (
      <span className="p-column-title">
        {data[locationField]}{' '}
        {status && (
          <>
            <Tooltip target={`#${travelStatusId}`} position="right">
              <span>{status}</span>
            </Tooltip>
            <i id={travelStatusId} className={`pi ${iconClass} ${iconColor}`} />
          </>
        )}
      </span>
    );
  };

  const participantStatusBodyTemplate = (data: IbookingsParticipantsList, statusProps: ColumnFieldDataProps) => {
    const bookingParticipantState = data[statusProps.field];
    switch (bookingParticipantState) {
      case BookingParticipantState.APPROVED:
        return <span className="status-success">Approved</span>;
      case BookingParticipantState.PENDING_APPROVAL:
        return <span className="status-dark">Pending</span>;
      case BookingParticipantState.FITNESS_REJECTED:
        return <span className="status-dark">Rejected</span>;
      case BookingParticipantState.WAITING_LIST:
        return <span className="status-warning">Waiting List</span>;
      default:
        return <span className="status-danger">{bookingParticipantState}</span>;
    }
  };

  const bookingStatusBodyTemplate = (data: IbookingsParticipantsList, bookingStatusProps: ColumnFieldDataProps) => {
    const status = data[bookingStatusProps.field];
    switch (status) {
      case BookingState.COMPLETED:
        return <span className="status-success">Completed</span>;
      case BookingState.PAYMENT:
        return <span className="status-dark">Payment</span>;
      case BookingState.ADD_PARTICIPANTS:
        return <span className="status-dark">Add Participant</span>;
      case BookingState.WAITING_LIST:
        return <span className="status-dark">Waiting List</span>;
      case BookingState.CANCELLED:
        return <span className="status-danger">Cancelled</span>;
      case BookingState.INACTIVE:
        return <span className="status-warning">Inactive</span>;
      case BookingState.DIFFICULT_TREK:
        return <span className="status-danger">Difficult Trek</span>;
      default:
        return <span className="status-warning">{status}</span>;
    }
  };

  const BookingsParticipantsTableHeader = (
    <div className="table-header">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e: any) => {
            setGlobalFilter(e.target.value);
          }}
          placeholder="Global Search"
        />
      </span>
    </div>
  );

  const actionTemplate = (data: IbookingsParticipantsList) => {
    return (
      <>
        <div className="d-flex align-items-center align-jus-con p-my-2">
          <div>
            <Link to={`/bookings/view/${data.bookingId}`} target="_blank">
              <i className="pi pi-eye icon-success-color" color="secondary" />
            </Link>
          </div>
        </div>
      </>
    );
  };

  const exportCSV = (selectionOnly: boolean) => {
    if (dt != null && dt.current != null) {
      dt.current?.exportCSV({ selectionOnly });
    }
  };

  const handlePaidCheckboxChange = () => {
    const display = !displayOnlyPaidTravels;
    setDisplayOnlyPaidTravels(display);
    updatePreferences({ displayOnlyPaidTravels: display });
  };

  return (
    <div>
      <div className="p-grid table-demo">
        <Toast ref={toast} />
        <div className="p-col-12">
          <div className="flex align-items-center export-buttons">
            <Button label="Export CSV" onClick={() => exportCSV(false)} className="p-button-success" />
          </div>
          <div>
            <DataTable
              ref={dt}
              value={participants}
              paginator
              scrollable
              style={{ width: '100%' }}
              rows={10}
              dataKey="id"
              tabIndex={0}
              rowHover
              selection={selectedParticipants}
              onSelectionChange={(e: any) => setSelectedParticipants(e.value)}
              globalFilter={globalFilter}
              emptyMessage="No record(s) found."
              header={BookingsParticipantsTableHeader}
              loading={loading}
            >
              <Column
                field="firstName"
                header="First Name"
                headerStyle={{ width: '110px' }}
                sortable
                body={bodyTemplate}
              />
              <Column
                field="lastName"
                header="Last Name"
                headerStyle={{ width: '110px' }}
                sortable
                body={bodyTemplate}
              />
              <Column field="participantId" header="Trekker Id" style={{ display: 'none' }} />
              <Column field="email" header="Email" headerStyle={{ width: '180px' }} sortable body={bodyTemplate} />
              <Column field="phone" header="Phone Number" headerStyle={{ width: '100px' }} body={bodyTemplate} />
              <Column field="height" header="Height" headerStyle={{ width: '50px' }} sortable body={bodyTemplate} />
              <Column field="weight" header="Weight" headerStyle={{ width: '50px' }} sortable body={bodyTemplate} />
              <Column field="bmi" header="Bmi" headerStyle={{ width: '50px' }} sortable body={bodyTemplate} />
              <Column field="age" header="Age" headerStyle={{ width: '75px' }} sortable body={bodyTemplate} />
              <Column field="dob" header="Dob" headerStyle={{ width: '75px' }} sortable body={bodyTemplate} />
              <Column field="gender" header="Gender" headerStyle={{ width: '75px' }} sortable body={bodyTemplate} />
              <Column
                field="userDetailsForDisplay.documentIdNumber"
                header="Document ID Number"
                style={{ display: 'none' }}
              />
              <Column
                field="userDetailsForDisplay.emergencyContactName"
                header="Emergency Contact Name"
                style={{ display: 'none' }}
              />
              <Column
                field="userDetailsForDisplay.emergencyContactRelationshipToYou"
                header="Relationship"
                style={{ display: 'none' }}
              />
              <Column
                field="userDetailsForDisplay.emergencyContactEmail"
                header="Emergency Contact Email"
                style={{ display: 'none' }}
              />
              <Column field="emergencyContactNumber" header="Emergency Contact Number" style={{ display: 'none' }} />
              <Column field="city" header="City" headerStyle={{ width: '90px' }} sortable body={bodyTemplate} />
              <Column
                field="completedTrekList"
                header="Trek History"
                headerStyle={{ width: '300px' }}
                body={completedTrekListBodyTemplate}
              />
              <Column
                field="pickupLocation"
                header="Pick-up Location"
                headerStyle={{ width: '180px' }}
                sortable
                body={locationTemplate}
              />
              <Column
                field="dropOffLocation"
                header="Drop-off Location"
                headerStyle={{ width: '180px' }}
                sortable
                body={locationTemplate}
              />
              <Column
                field="bookingParticipantState"
                header="Participant Status"
                headerStyle={{ width: '80px' }}
                sortable
                body={participantStatusBodyTemplate}
              />
              <Column
                field="bookingState"
                header="Booking Status"
                headerStyle={{ width: '80px' }}
                sortable
                body={bookingStatusBodyTemplate}
                />
              <Column
                field="shieldAmount"
                headerStyle={{ width: '100px' }}
                header="Shield Amount"
                body={bodyTemplate}
                sortable
              />
              <Column
                field="backpackOffloadingState"
                header="Backpack"
                headerStyle={{ width: '80px' }}
                sortable
                body={bodyTemplate}
              />
              <Column field="comments" header="Comment" headerStyle={{ width: '120px' }} sortable body={bodyTemplate} />
              <Column field="flag" header="User Flag" headerStyle={{ width: '60px' }} body={flagTemplate} sortable />
              <Column
                field="userComment"
                header="User Comment"
                headerStyle={{ width: '120px' }}
                sortable
                body={bodyTemplate}
              />
              <Column headerStyle={{ width: '100px', textAlign: 'center' }} body={actionTemplate} header="Action" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

const fetchTreksForAllParticipants = async (
  bookingService: typeof BookingParticipantService,
  participants: IbookingsParticipantsList[],
  setParticipants: Dispatch<SetStateAction<IbookingsParticipantsList[] | undefined>>,
) => {
  // fetch completed treks for each participant 
  const data = await Promise.all(
    participants.map((participant) => {
      return bookingService.get(0, 100, {
        userId: participant.userDetailsForDisplay.id,
        bookingState: BookingState.COMPLETED,
        participantState: `${BookingParticipantState.APPROVED},${BookingParticipantState.PENDING_APPROVAL},${BookingParticipantState.FITNESS_REJECTED}`,
      } as IParticipantsFilter);
    }),
  );

  // add this data to each participant
  const updatedParticipants = participants.map((participant, index) => {
    return {
      ...participant,
      completedTrekList: data[index].data.length ? data[index].data.map((trek) => trek.trekName) : ["none"],
    };
  });

  // update state
  setParticipants(updatedParticipants);
};

export default BatchConfirmedViewComponent;
