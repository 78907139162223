/* eslint-disable */
import React from 'react';

const AppFooter = () => {
  return (
    <div className="layout-footer">
      <div className="p-grid">
       
      </div>
    </div>
  );
};

export default AppFooter;
