/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import AppWrapper from './components/layout/AppWrapper';
import UserService from './api/UserService';
import { IntlProvider } from 'react-intl';

const locale = navigator.language;

// React Render
const renderApp = () =>
  ReactDOM.render(
    <HashRouter>
      <IntlProvider locale={locale}>
        <AppWrapper />
      </IntlProvider>
    </HashRouter>,
    document.getElementById('root'),
  );

UserService.initKeycloak(renderApp);
