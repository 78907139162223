/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import UserService from '../../api/UserService';

const AppTopbar = (props: any) => {
  const history = useHistory();
  const topbarMenuClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });
  const profileItemClassName = classNames('user-profile', {
    'active-topmenuitem': props.activeTopbarItem === 'profile',
  });

  const activeTopbarItemClassName = (name: string) => {
    return name === props.activeTopbarItem ? 'active-topmenuitem' : '';
  };

  const isProfilePopup = props.profileMode === 'popup' || props.isHorizontal;

  return (
    <div className="layout-topbar">
      <div className="layout-topbar-opcity">
        <button
          className="layout-topbar-logo p-link"
          onClick={(e) => {
            history.push('/');
          }}
        >
          <img
            id="layout-topbar-logo"
            src="assets/layout/images/logo.png"
            alt="babylon-layout"
            onClick={(e) => {
              history.push('/');
            }}
          />
        </button>

        <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
          <i className="pi pi-bars"></i>
        </button>

        <button id="topbar-menu-button" className="p-link" onClick={props.onTopbarMenuButtonClick}>
          <i className="pi pi-ellipsis-v"></i>
        </button>

        <ul className={topbarMenuClassName}>
          {isProfilePopup && (
            <li className={profileItemClassName}>
              <div className="flex flex-align-center">
                <div>
                  <button
                    className="p-link flex flex-align-center"
                    onClick={(e) => props.onTopbarItemClick(e, 'profile')}
                  >
                    <img
                      alt="babylon-layout"
                      src="assets/layout/images/avatar.png"
                      className="icon-hidden"
                      width="50px"
                    />
                    <i className="pi pi-user"></i>
                    <span className="pl-2">
                      {UserService.getName()} <i className="pi pi-angle-down avatar-icon"></i>
                    </span>
                  </button>
                </div>
              </div>
              <ul className={classNames({ fadeInDown: !props.isMobile() })}>
                <li role="menuitem">
                  <button
                    className="p-link"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_IAM_URL}/auth/realms/IndiaHikes/account/?referrer=tms-ui#`,
                        '_blank',
                      )
                    }
                  >
                    <i className="pi pi-user"></i>
                    <span>Profile</span>
                  </button>
                </li>
                {/*<li role="menuitem">*/}
                {/*  <button className="p-link" onClick={(e) => {history.push('/user-vouchers');}}>*/}
                {/*    <i className="pi pi-cog"></i>*/}
                {/*    <span>Vouchers</span>*/}
                {/*  </button>*/}
                {/*</li>*/}
                {/*<li role="menuitem">*/}
                {/*  <button className="p-link" onClick={(e) => {history.push('/user-receipts');}}>*/}
                {/*    <i className="pi pi-envelope"></i>*/}
                {/*    <span>Receipts</span>*/}
                {/*  </button>*/}
                {/*</li>*/}
                {/*<li role="menuitem">*/}
                {/*  <button className="p-link">*/}
                {/*    <i className="pi pi-bell"></i>*/}
                {/*    <span>Certificates</span>*/}
                {/*  </button>*/}
                {/*</li>*/}
                <li role="menuitem">
                  <button className="p-link" onClick={() => UserService.doLogout()}>
                    <i className="pi pi-user"></i>

                    <span>Logout</span>
                  </button>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AppTopbar;
