/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import specialTrekTypeService from '../../api/SpecialTrekTypeService';

interface SpecialTrekType {
  id: number;
  name: string;
}

const SpecialTrekTypeList: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const toast = useRef<Toast>(null);
  const [specialTrekTypes, setSpecialTrekTypes] = useState<SpecialTrekType[]>([]);
  const [editingId, setEditingId] = useState<number | null>(null);

  useEffect(() => {
    fetchSpecialTrekTypes();
  }, []);

  const gridTableHeader = <div className="table-header">All Special Trek Types</div>;

  const addNewSpecialTrekType = () => {
    history.push('/special-trek-types/add');
  };

  const actionTemplate = (data: SpecialTrekType) => (
    <Button
      icon="pi pi-pencil"
      className="p-button-info"
      style={{ width: '2rem', height: '2rem', minWidth: '2rem', padding: 0 }}
      onClick={() => setEditingId(editingId === data.id ? null : data.id)}
    />
  );

  const renderNameColumn = (rowData: SpecialTrekType) => (
    editingId === rowData.id ? (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <input
          type="text"
          value={rowData.name}
          onChange={e => updateName(e, rowData.id)}
          onKeyDown={e => handleKeyDown(e, rowData.id)}
          style={{ height: '30px' }}
        />
        <Button
          icon="pi pi-check"
          onClick={() => finalizeEdit(rowData.id)}
          style={{ height: '30px' }}
        />
      </div>
    ) : <span>{rowData.name}</span>
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, id: number) => {
    if (event.key === 'Escape') {
      setEditingId(null);
    }
  };

  const updateName = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setSpecialTrekTypes(specialTrekTypes.map(trekType =>
      trekType.id === id ? { ...trekType, name: event.target.value } : trekType
    ));
  };

  const finalizeEdit = (id: number) => {
    const trekTypeToUpdate = specialTrekTypes.find(trekType => trekType.id === id);
    if (trekTypeToUpdate) {
      specialTrekTypeService.update(id, trekTypeToUpdate)
        .then(() => {
          setEditingId(null);
          fetchSpecialTrekTypes();
        })
        .catch(console.error);
    }
  };
  
  // Since delete is not required, the following code is commented out for now, if required, it can be uncommented and used
  // const onDelete = (id: number) => {
  //   confirmDialog({
  //     message: 'Are you sure you want to delete the special trek type?',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       specialTrekTypeService
  //         .deleteById(id)
  //         .then((res) => {
  //           if (res.status === 200) {
  //             const tst: any = toast?.current;
  //             tst.show({
  //               severity: 'success',
  //               summary: 'Successful',
  //               detail: 'Special Trek Type Deleted',
  //               life: 3000,
  //             });
  //             fetchSpecialTrekTypes();
  //           }
  //         })
  //         .catch((err) => {
  //           const tst: any = toast?.current;
  //           tst.show({
  //             severity: 'error',
  //             summary: 'Error',
  //             detail: 'Something went wrong',
  //             life: 3000,
  //           });
  //         });
  //     },
  //   });
  // };

  // This also is not required for now, if required, it can be uncommented and used
  // const actionTemplate = (data: any) => {
  //   return (
  //     <>
  //       <div className="d-flex align-items-center">
  //         <div className="p-px-2">
  //           <Button
  //             label=""
  //             icon="pi pi-trash"
  //             className="p-button-danger p-icon-btn-custom"
  //             onClick={(e) => {
  //               onDelete(data.id);
  //             }}
  //           />
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const fetchSpecialTrekTypes = () => {
    setLoading(true);
    specialTrekTypeService.getAll()
      .then((res) => {
        setSpecialTrekTypes(res.data);
      })
      .catch((err) => {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong',
          life: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const items = [{ label: 'Treks', url: '/treks' }];
  const home = { icon: 'pi pi-home', url: '/' };

  return (
    <div className="p-grid table-demo">
      <Toast ref={toast} />
      <div className="p-col-12">
        <div className="align-items-center mb-2">
          <BreadCrumb model={items} home={home} />
        </div>
        <div className="d-flex align-items-center mb-2">
          <div className="flex-grow-1">
            <div className="display-1">Special Trek Types</div>
          </div>
          <div>
            <Button
              label="Create New Special Trek Type"
              onClick={addNewSpecialTrekType}
              className="p-button-lg p-button-secondary p-button-dark"
              icon="pi pi-arrow-right"
              iconPos="right"
            />
          </div>
        </div>
        <p></p>
        <div className="card">
          <DataTable
            scrollable
            style={{ width: '100%' }}
            value={specialTrekTypes}
            scrollHeight="calc(100vh - 440px)"
            loading={loading}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            emptyMessage="No record(s) found."
            header={gridTableHeader}
          >
            <Column field="id" header="Id" headerStyle={{ width: '112px' }} sortable></Column>
            <Column field="name" header="Name" headerStyle={{ width: '100px' }} sortable body={renderNameColumn}></Column>
            <Column
              headerStyle={{ width: '30px', textAlign: 'center' }}
              bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
              body={actionTemplate}
              header="Action"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};
export default SpecialTrekTypeList;
