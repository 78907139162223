/* eslint-disable */
import React, { useEffect } from 'react';

import withLoader from '../../helpers/withloader';
import './Unauthorized.scss';

const Unauthorized: React.FC = () => {
  useEffect(() => {
    // @ts-ignore
    window.location = 'https://indiahikes.com/user-dashboard/user-upcoming-treks';
  }, []);
  return (
    <div className="container">
      <h1>
        4
        <div className="lock">
          <div className="top"></div>
          <div className="bottom"></div>
        </div>
        3
      </h1>
      <p>Access not granted. Please contact Indiahikes</p>
    </div>
  );
};
export default withLoader(Unauthorized);
