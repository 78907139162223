export interface IVoucherFilter {
  email: string | undefined;
  voucherStatuses: VoucherState | undefined;
  voucherTypeIds: number | undefined;
  validFrom: string | undefined;
  validTo: string | undefined;
  usedFrom: string | undefined;
  usedTo: string | undefined;
  userId: number | undefined;
  createdAtFrom: string | undefined;
  createdAtTo: string | undefined;
}

export enum VoucherState {
  ACTIVE = 'ALLOCATED',
  USED = 'USED',
}

export interface IVoucherCodeFilter {
  voucherCodeStatuses: VoucherCodeState | undefined;
  trekIds?: number | undefined;
  createdAtFrom: string | undefined;
  createdAtTo: string | undefined;
}

export enum VoucherCodeState {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}
