/* eslint-disable */
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';

export enum IconButtonVariant {
  PRIMARY = 'primary',
  WARNING = 'warning',
  DANGER = 'danger',
}

type ComponentProperties = {
  defaultIcon: string;
  loaderIcon?: string;
  onClick: () => void;
  loading: boolean;
  buttonWithLoader?: boolean;
  variant?: IconButtonVariant;
};

const IconButton: React.FC<ComponentProperties> = (props) => {
  const { defaultIcon, loaderIcon, onClick, loading, buttonWithLoader, variant } = props;
  const [isLoading, setIsLoading] = useState(false);
  const icon = isLoading ? loaderIcon || 'pi pi-spin pi-spinner' : defaultIcon;
  const variantClass = variant ? `p-button-${variant}` : '';
  const buttonClasses = `p-button-rounded p-button-outlined ${variantClass}`;

  useEffect(() => {
    if (!loading) setIsLoading(false);
  }, [loading]);

  const handleClick = () => {
    if (buttonWithLoader) setIsLoading(true);
    onClick();
  };

  return <Button icon={icon} className={buttonClasses} disabled={isLoading} onClick={() => handleClick()} />;
};

export default IconButton;
