import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { InputNumber, InputNumberValueChangeParams } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { AutoComplete, AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import withLoader from '../../helpers/withloader';
import {
  IRouteData,
  ISeasonDetailFormModel,
  ISeasonsApiModel,
  ITrekLookupModel,
  IUserLookupModel,
  UserRole,
} from '../../models/index';
import SeasonService from '../../api/SeasonService';
import TrekService from '../../api/TrekService';
import LookupService from '../../api/LookupService';
import { ISpecialTrekTypeModel } from '../../models/Trek/SpecialTrekModel';
import { FeeType, WaypointType } from './SeasonConstants';
import TravelRoutesModal from './TravelRoutesModal';
import Overlay from '../common/Overlay';
import { ITrekWaypointsLookupModel } from '../../models/Lookups/TrekLookupModel';
import { DIFFERENT_SHIELD_MESSAGE_TYPES, SHIELD_FEE_TYPE_OPTIONS } from './constants';
import "./SeasonList.scss";

interface RouteParams {
  id: string;
}

const SeasonDetail: React.FC<RouteComponentProps<RouteParams>> = ({ history, match }) => {
  const { params } = match;
  const id: number = parseInt(params.id, 10);
  const toast = useRef<Toast>(null);
  const firstRender = useRef(true);

  const [seasonId, setSeasonId] = useState(id);
  const [isAddMode, setIsAddMode] = useState(!id);
  const [displayDialog, setDisplayDialog] = useState(false);

  const [autoTrekValueData, setAutoTrekValueData] = useState<ITrekLookupModel[]>([]);
  const [selectedTrekValue, setSelectedTrekValue] = useState<ITrekLookupModel | null>(null);
  const [autoFilteredValue, setAutoFilteredValue] = useState<ITrekLookupModel[]>([]);

  const [autoTransportUserData, setAutoTransportUserData] = useState<IUserLookupModel[]>([]);
  const [autoFilteredTransportUserValue, setAutoFilteredTransportUserValue] = useState<IUserLookupModel[]>([]);
  const [selectedTransportUserAutoValue, setSelectedTransportUserAutoValue] = useState<IUserLookupModel[]>();

  const [autoSlopeUserData, setAutoSlopeUserData] = useState<IUserLookupModel[]>([]);
  const [autoFilteredSlopeUserValue, setAutoFilteredSlopeUserValue] = useState<IUserLookupModel[]>([]);
  const [selectedSlopeUserAutoValue, setSelectedSlopeUserAutoValue] = useState<IUserLookupModel[]>();

  const [autoSpecialTrekData, setAutoSpecialTrekData] = useState<ISpecialTrekTypeModel[]>([]);
  const [autoFilteredSpecialTrekValue, setAutoFilteredSpecialTrekValue] = useState<ISpecialTrekTypeModel[]>([]);
  const [selectedSpecialTrekAutoValue, setSelectedSpecialTrekAutoValue] = useState<ISpecialTrekTypeModel[]>();

  const [travelRoutesData, setTravelRoutesData] = useState<IRouteData[]>([]);
  const [loading, setLoading] = useState(true);
  const [defaultShieldDescriptionId, setDefaultShieldDescriptionId] = useState<
    typeof DIFFERENT_SHIELD_MESSAGE_TYPES[number]['id']
  >('none');
  const [shieldFeeTypeSuggestions, setShieldFeeTypeSuggestions] = useState<string[]>(SHIELD_FEE_TYPE_OPTIONS);

  const defaultValues = {
    trekFee: 0,
    insuranceOpted: true,
    backPackOffloading: 0,
    tentSharing: 0,
    offlineBackPack: 0,
    shield: 0,
    shieldMessage: '',
    shieldFeeType: [],
  };

  // filter the types on autocomplete
  const filterShieldTypes = (e: any) => {
    setShieldFeeTypeSuggestions(SHIELD_FEE_TYPE_OPTIONS.filter((option) => option.includes(e.query)));
  };

  const validationSchema = Yup.object({
    trekId: Yup.string().required('Trek is required'),
    startDate: Yup.date().required('Start Date  is required'),
    endDate: Yup.date()
      .required('End Date  is required')
      .test('enddate-comparer', 'End date should greater than start-date', (value) => {
        if (!value) return false;
        const startDt: Date = new Date(getValues('startDate'));
        const endDt: Date = new Date(value);
        const valid: boolean = endDt > startDt;
        return valid;
      }),
    trekFee: Yup.number().required('TrekFee  is required').min(0),
    shieldFeeType: Yup.array().when('shield', {
      is: (shieldFee: any) => shieldFee,
      then: Yup.array().min(1, 'Shield Fee Type is required if Shield Amount is added'),
      otherwise: Yup.array().notRequired(),
    }),
  });

  const { handleSubmit, reset, setValue, control, getValues, errors, formState } = useForm<ISeasonDetailFormModel>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues,
  });

  const filteredUsers = (userData: IUserLookupModel[], filterIds: number[]) => {
    const items: IUserLookupModel[] = [];
    filterIds?.forEach((x) => {
      const val = userData?.find((y) => y.id === x);
      if (val !== undefined) items.push(val);
    });
    return items;
  };

  const filteredSpecialTreks = (specialTrekData: ISpecialTrekTypeModel[], filterIds: number[]) => {
    let items: ISpecialTrekTypeModel[] = [];
    items = specialTrekData?.filter((obj) => filterIds.includes(obj.id));
    return items;
  };

  const fetchTravelRoutes = (waypointsData: ITrekWaypointsLookupModel) => {
    const pickups = waypointsData.trekWayPointList.filter((point) => point.type === WaypointType.PICKUP);
    const dropOffs = waypointsData.trekWayPointList.filter((point) => point.type === WaypointType.DROP_OFF);

    const routes: IRouteData[] = [];

    pickups.forEach((pickup) => {
      routes.push({
        startPoint: {
          id: pickup.locationId,
          locationName: pickup.name,
          locationType: WaypointType.PICKUP,
        },
        endPoint: {
          id: waypointsData.startPointLocation.id,
          locationName: waypointsData.startPointLocation.name,
          locationType: WaypointType.START_POINT,
        },
      });
    });

    dropOffs.forEach((dropOff) => {
      routes.push({
        startPoint: {
          id: waypointsData.endPointLocation.id,
          locationName: waypointsData.endPointLocation.name,
          locationType: WaypointType.END_POINT,
        },
        endPoint: {
          id: dropOff.locationId,
          locationName: dropOff.name,
          locationType: WaypointType.DROP_OFF,
        },
      });
    });

    return routes;
  };

  const fetchWaypointsDataForTrek = (trekId: number) => {
    if (!trekId) return;
    LookupService.getTrekWaypoints(trekId).then((waypointsData) => {
      const routesData = fetchTravelRoutes(waypointsData);
      setTravelRoutesData(routesData);
    });
  };

  const getAndBindData = useCallback(
    (
      trekData: ITrekLookupModel[],
      transPortUserData: IUserLookupModel[],
      slopeManagerUsersData: IUserLookupModel[],
      specialTrekTypesData: ISpecialTrekTypeModel[],
    ) => {
      // get user and set form fields
      SeasonService.getById(seasonId)
        .then((seasonData: ISeasonsApiModel) => {
          const selectedTrekObject = trekData?.find((x) => x.trekId === seasonData.trekId);
          setSelectedTrekValue(selectedTrekObject || null);

          const selectedTransUsers = filteredUsers(transPortUserData, seasonData.transportCoordinatorIds);
          setSelectedTransportUserAutoValue(selectedTransUsers || []);

          const selectedSlopeManagers = filteredUsers(slopeManagerUsersData, seasonData.slopeManagerIds);
          setSelectedSlopeUserAutoValue(selectedSlopeManagers || []);

          const selectedSpecialTreks = filteredSpecialTreks(specialTrekTypesData, seasonData.specialTrekTypes);
          setSelectedSpecialTrekAutoValue(selectedSpecialTreks || []);

          const selectedObject = trekData?.find((x) => x.trekId === seasonData.trekId);
          setSelectedTrekValue(selectedObject || null);

          const routesData = fetchTravelRoutes({
            startPointLocation: seasonData.startPointLocation,
            endPointLocation: seasonData.endPointLocation,
            trekWayPointList: seasonData.trekWayPoints,
          });
          setTravelRoutesData(routesData);

          setValue('trekId', seasonData.trekId);
          setValue('startDate', new Date(seasonData.startDate));
          setValue('endDate', new Date(seasonData.endDate));
          setValue('trekFee', seasonData.seasonFees.find((x) => x.feeType === FeeType.TREK)?.amount);
          setValue(
            'backPackOffloading',
            seasonData.seasonFees.find((x) => x.feeType === FeeType.BACKPACK_OFFLOADING)?.amount,
          );
          setValue('preTrekBriefingLink', seasonData.preTrekBriefingLink);
          setValue('tentSharing', seasonData.seasonFees.find((x) => x.feeType === FeeType.TENT_SHARING)?.amount);
          setValue('insuranceOpted', seasonData.insuranceOpted);
          setValue(
            'offlineBackPack',
            seasonData.seasonFees.find((x) => x.feeType === FeeType.OFFLINE_BACKPACK_OFFLOADING)?.amount,
          );
          setValue('transportCoordinatorIds', selectedTransUsers);
          setValue('slopeManagerIds', selectedSlopeManagers);
          setValue('specialTrekTypes', selectedSpecialTreks);
          const shieldFees = seasonData.seasonFees.find((x) => x.feeType === 'SHIELD')?.amount;
          setValue('shield', shieldFees || 0);

          const shieldDescription = seasonData.seasonFees.find((x) => x.feeType === 'SHIELD')?.description ?? '';
          setValue('shieldDescription', shieldDescription);

          const shieldFeeTypes = seasonData.seasonFees
            .filter((x) => x.feeType === 'SHIELD')
            ?.map((x) => x.feeDisplayType);

          const shieldFeeTypeObjects = SHIELD_FEE_TYPE_OPTIONS.filter((option) => shieldFeeTypes.includes(option as any));

          setValue('shieldFeeType', shieldFeeTypeObjects);
        })
        .catch(() => {
          const tst = toast?.current;
          tst?.show({
            severity: 'error',
            summary: 'Data fetching failed; Re-try in few mins. ...If not succeeded contact support team',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [seasonId, setValue],
  );

  useEffect(() => {
    if (!firstRender.current) return;
    firstRender.current = false;

    const trekService = TrekService;
    const lookupService = LookupService;

    Promise.all([
      trekService.getByLookupFormat(),
      lookupService.getUser(UserRole.TRANSPORT_COORDINATOR),
      lookupService.getUser(UserRole.SLOPE_MANAGER),
      lookupService.getSpecialTrekTypes(),
    ])
      .then(([trekData, transUserData, slopeUserData, specialTrekData]) => {
        setAutoTrekValueData(trekData);
        setAutoTransportUserData(transUserData);
        setAutoSlopeUserData(slopeUserData);
        setAutoSpecialTrekData(specialTrekData);

        if (!isAddMode) {
          getAndBindData(trekData, transUserData, slopeUserData, specialTrekData);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        const tst = toast?.current;
        tst?.show({
          severity: 'error',
          summary: 'Error fetching data. Contact support team',
        });
        setLoading(false);
      });
  }, [getAndBindData, isAddMode]);

  const autoSearchTrek = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoFilteredValue([...autoTrekValueData]);
    } else {
      const avValue = autoTrekValueData;
      setAutoFilteredValue(
        avValue.filter((location) => {
          return location.name.toLowerCase().includes(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchTransportUsers = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoFilteredTransportUserValue([...autoTransportUserData]);
    } else {
      setAutoFilteredTransportUserValue(
        autoTransportUserData.filter((user) => {
          return user?.firstName?.toLowerCase().includes(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchSlopeUsers = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoFilteredSlopeUserValue([...autoSlopeUserData]);
    } else {
      setAutoFilteredSlopeUserValue(
        autoSlopeUserData.filter((user) => {
          return user?.firstName?.toLowerCase().includes(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchSpecialTreks = (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.trim().length) {
      setAutoFilteredSpecialTrekValue(
        autoSpecialTrekData.filter((trek) => {
          return trek?.name?.toLowerCase().includes(event.query.toLowerCase());
        }),
      );
    } else {
      setAutoFilteredSpecialTrekValue([...autoSpecialTrekData]);
    }
  };

  const createSeason = (data: ISeasonDetailFormModel) => {
    return SeasonService.create(data)
      .then((res) => {
        const tst = toast?.current;
        tst?.show({ severity: 'success', summary: ' Season Created Successfully', detail: '' });
        setSeasonId(res.id);
        setIsAddMode(false);
        setDisplayDialog(true);
      })
      .catch((res) => {
        const tst = toast?.current;
        if (res?.response?.data?.message)
          tst?.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst?.show({
            severity: 'error',
            summary: 'Creation failed;Re-try in few mins. ...If not succeeded contact support team',
          });
      });
  };

  const updateSeason = (seasonIdParam: number, data: ISeasonDetailFormModel) => {
    return SeasonService.update(seasonIdParam, data)
      .then(() => {
        const tst = toast?.current;
        tst?.show({ severity: 'success', summary: ' Season updated Successfully', detail: '' });
        history.push('/season');
      })
      .catch((res) => {
        const tst = toast?.current;
        if (res.response.data.message)
          tst?.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst?.show({
            severity: 'error',
            summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
          });
      });
  };

  const onSubmit = (data: ISeasonDetailFormModel) => {
    return isAddMode ? createSeason(data) : updateSeason(seasonId, data);
  };

  const handleCancelClick = () => {
    return isAddMode ? history.push('.') : history.push('..');
  };

  return (
    <>
      {loading && <Overlay text="Loading..." icon="pi-spinner" iconSpin />}
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
        <Toast ref={toast} />
        <div className="d-flex align-items-center details-title">
          <div className="flex-grow-1">
            <div className="display-1">{isAddMode ? 'Add Season' : 'Edit Season'}</div>
          </div>
          <div>
            <Link to="/season">
              <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }} />
            </Link>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12 p-lg-6 p-md-12">
            <div className="card p-fluid">
              <h4>Season details</h4>
              <div className="p-grid">
                <div className="p-col-12 p-md-12">
                  <div className="required">
                    <div className="p-mb-2">
                      <label htmlFor="name">Trek:</label>
                    </div>
                    <div>
                      <Controller
                        name="trekId"
                        control={control}
                        render={({ onChange }) => (
                          <AutoComplete
                            placeholder="Search"
                            autoFocus
                            dropdown
                            forceSelection
                            value={selectedTrekValue}
                            onChange={(e) => {
                              setSelectedTrekValue(e.value);
                              onChange(e.value?.trekId);
                            }}
                            onSelect={(e) => fetchWaypointsDataForTrek(e.value?.trekId)}
                            suggestions={autoFilteredValue}
                            disabled={!isAddMode}
                            completeMethod={autoSearchTrek}
                            field="name"
                          />
                        )}
                      />
                      {errors.trekId && (
                        <span className="p-error">
                          <p>Error:{errors.trekId?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-lg-6 p-md-12">
                  <div className="required">
                    <div className="p-mb-2">
                      <label htmlFor="name">Start Date:</label>
                    </div>
                    <div>
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            value={value}
                            onChange={(e) => onChange(e.value)}
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                          />
                        )}
                      />
                      {errors.startDate && (
                        <span className="p-error">
                          <p>Error:{errors.startDate?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-lg-6 p-md-12">
                  <div className="required">
                    <div className="p-mb-2">
                      <label htmlFor="name">End Date:</label>
                    </div>
                    <div>
                      <Controller
                        name="endDate"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            value={value}
                            onChange={(e) => onChange(e.value)}
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                          />
                        )}
                      />
                      {errors.endDate && (
                        <span className="p-error">
                          <p>Error:{errors.endDate?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Transport Coordinator(s):</label>
                    </div>
                    <div>
                      <Controller
                        name="transportCoordinatorIds"
                        control={control}
                        render={({ onChange }) => (
                          <AutoComplete
                            placeholder="Search"
                            dropdown
                            multiple
                            value={selectedTransportUserAutoValue}
                            onChange={(e) => {
                              setSelectedTransportUserAutoValue(e.value);
                              onChange(e.value);
                            }}
                            suggestions={autoFilteredTransportUserValue}
                            completeMethod={autoSearchTransportUsers}
                            field="firstName"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Slope Managers(s):</label>
                    </div>
                    <div>
                      <Controller
                        name="slopeManagerIds"
                        control={control}
                        render={({ onChange }) => (
                          <AutoComplete
                            placeholder="Search"
                            dropdown
                            multiple
                            value={selectedSlopeUserAutoValue}
                            onChange={(e) => {
                              setSelectedSlopeUserAutoValue(e.value);
                              onChange(e.value);
                            }}
                            suggestions={autoFilteredSlopeUserValue}
                            completeMethod={autoSearchSlopeUsers}
                            field="firstName"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Special Trek(s):</label>
                    </div>
                    <div>
                      <Controller
                        name="specialTrekTypes"
                        control={control}
                        render={({ onChange }) => (
                          <AutoComplete
                            placeholder="Search"
                            dropdown
                            multiple
                            value={selectedSpecialTrekAutoValue}
                            onChange={(e) => {
                              setSelectedSpecialTrekAutoValue(e.value);
                              onChange(e.value);
                            }}
                            suggestions={autoFilteredSpecialTrekValue}
                            completeMethod={autoSearchSpecialTreks}
                            field="name"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="preTrekBriefingLink">Pre-trek briefing link:</label>
                    </div>
                    <div>
                      <Controller
                        name="preTrekBriefingLink"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value = "" }) => <InputText value={value} onChange={onChange} />}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-field p-grid">
                  <label htmlFor="insuranceOpted" className="p-col-12 p-md-12">
                    Insurance Opted
                  </label>
                  <div className="p-col-12 p-md-10">
                    <Controller
                      name="insuranceOpted"
                      control={control}
                      render={({ onChange, value }) => (
                        <Checkbox
                          checked={value}
                          onChange={(e) => {
                            onChange(e.checked);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-col-12 p-lg-6 p-md-12">
            <div className="card p-fluid">
              <h4>Fees</h4>
              <div className="p-grid">
                <div className="p-col-12 p-md-12">
                  <div className="required">
                    <div className="p-mb-2">
                      <label htmlFor="name">Trek:</label>
                    </div>
                    <div>
                      <Controller
                        name="trekFee"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: InputNumberValueChangeParams) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                      {errors.trekFee && (
                        <span className="p-error">
                          <p>Error:{errors.trekFee?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Back pack offloading:</label>
                    </div>
                    <div>
                      <Controller
                        name="backPackOffloading"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: InputNumberValueChangeParams) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">TentSharing:</label>
                    </div>
                    <div>
                      <Controller
                        name="tentSharing"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: InputNumberValueChangeParams) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Offline Backpack offLoading:</label>
                    </div>
                    <div>
                      <Controller
                        name="offlineBackPack"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: InputNumberValueChangeParams) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="travelRoutes">Travel Routes:</label>
                    </div>
                    <TravelRoutesModal
                      seasonId={seasonId}
                      travelRoutesData={travelRoutesData}
                      displayDialog={displayDialog}
                    />
                  </div>
                </div>
                <div className="p-col-6">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="shield">Shield Amount:</label>
                    </div>
                    <div>
                      <Controller
                        name="shield"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: any) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-6">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="shieldType">Shield Type:</label>
                    </div>
                    <div>
                      <Controller
                        name="shieldFeeType"
                        control={control}
                        render={({ onChange, value }) => (
                          <AutoComplete
                            placeholder="Search"
                            dropdown
                            multiple
                            value={value}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            completeMethod={filterShieldTypes}
                            suggestions={shieldFeeTypeSuggestions}
                          />
                        )}
                      />
                    </div>
                    {errors.shieldFeeType && (
                      <span className="p-error">
                        <p>Error:{((errors.shieldFeeType as unknown) as FieldError).message}</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className="p-col-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="shieldDescription">Shield Message:</label>
                    </div>
                    <div className="flex-column">
                      <Controller
                        name="shieldDescription"
                        control={control}
                        render={({ onChange, value }) => {
                          const inputValue =
                            defaultShieldDescriptionId === 'none'
                              ? value
                              : DIFFERENT_SHIELD_MESSAGE_TYPES.find(
                                  (messageType) => messageType.id === defaultShieldDescriptionId,
                                )?.description;

                          // since inputValue is controlled externally,
                          // calling onChange so that form recieves the updated value
                          if (value !== inputValue) {
                            onChange(inputValue);
                          }
                          return (
                            <InputTextarea
                              value={inputValue}
                              onChange={(e: any) => {
                                setDefaultShieldDescriptionId('none');
                                onChange(e.target.value);
                              }}
                              maxLength={1000}
                              rows={12}
                            />
                          );
                        }}
                      />
                      <div className="d-flex shield-radio-box-container">
                        {DIFFERENT_SHIELD_MESSAGE_TYPES.map((messageType) => (
                          <div key={messageType.id}>
                            <RadioButton
                              inputId={messageType.id}
                              name="shieldMessageType"
                              value={messageType.id}
                              onChange={(e) => setDefaultShieldDescriptionId(e.value)}
                              checked={defaultShieldDescriptionId === messageType.id}
                            />
                            <label htmlFor={messageType.id}>{messageType.label}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-cpl-12 p-grid p-justify-end p-mt-2">
          <div className="">
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-warning p-button-lg"
              onClick={handleCancelClick}
            />
          </div>
          <div className="">
            <Button
              type="submit"
              label="Submit"
              icon="pi pi-check"
              className="p-mx-2 p-button-lg p-button-secondary"
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default withLoader(withRouter(SeasonDetail));
