/* eslint-disable */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import BookingService from '../../api/BookingService';
import { IBookingUIModel, IParticipant, IParticipantFeeEditModel } from '../../models';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const ParticipantEdit: React.FC<RouteComponentProps> = ({ history, match }) => {
  const params: any = match.params;
  const id: number = parseInt(params.id, 10);
  const participantId: number = parseInt(params.participantId, 10);
  const [shown, setShown] = useState(false);
  const [participantProfile, setParticipantProfile] = useState<IParticipant>();
  const toast = useRef(null);
  const [bookingModel, setBookingModel] = useState<IBookingUIModel>();
  const [batchDate, setBatchDate] = useState<string>();

  useEffect(() => {
    BookingService.getById(id).then((bookingData: IBookingUIModel) => {
      setBookingModel(bookingData);
      fetchParticipantData();
    });
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        trekFee: Yup.number().required('Trek Fee is required'),
      }),
    [],
  );

  // functions to build form returned by useForm() hook
  const { handleSubmit, reset, setValue, control, errors, formState, getValues } = useForm<IParticipantFeeEditModel>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    return updateParticipant(data);
  };

  const updateParticipant = (data: any) => {
    return BookingService.updateTrekFee(id, participantId, participantId, data.trekFee, data.insuranceAmount)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Participant record updated Successfully', detail: '' });
        history.push(`/bookings/view/${id}`);
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res.response.data.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const validationSummary = (
    <div className="validation-summary-error">
      <pre>
        {Object.keys(errors).length > 0 && (
          <div>
            Please fill in the following required information:
            <ul>
              {Object.keys(errors).map((field) => (
                <li>{field}</li>
              ))}
            </ul>
          </div>
        )}
      </pre>
    </div>
  );

  const handleCancelClick = () => {
    history.push(`/bookings/view/${id}`);
  };

  const fetchParticipantData = () => {
    BookingService.getParticipants(id).then((data: IParticipant[]) => {
      const filteredData = data.find((x) => x.participantId == participantId);
      //mapData(filteredData);
      if (filteredData != undefined) {
        setParticipantProfile(filteredData);
        setShown(true);
      }
    });
  };

  return (
    <>
      <div>
        {shown == true && (
          <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
            <Toast ref={toast}></Toast>
            <div className="d-flex align-items-center details-title">
              <div className="flex-grow-1">
                <div className="display-1">Edit Participant</div>
              </div>
              <div>
                <Link to="/bookings">
                  <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }} />
                </Link>
              </div>
            </div>

            <div className="card p-fluid">
              <div className="p-grid">
                <div className="p-col-12 p-md-12 p-lg-8">
                  <div className="p-field p-grid">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="participantname" className="p-col-12 p-md-4 p-mb-md-0">
                      Name:
                    </label>
                    <div className="p-col-12 p-md-8">
                      <InputText value={participantProfile?.userDetailsForDisplay?.firstName} disabled />
                    </div>
                  </div>
                  <div className="p-field p-grid">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="ap" className="p-col-12 p-md-4 p-mb-md-0">
                      Amount paid:
                    </label>
                    <div className="p-col-12 p-md-8">
                      <InputText value={participantProfile?.amountPaid} disabled />
                    </div>
                  </div>
                  <div className="p-field p-grid">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="st" className="p-col-12 p-md-4 p-mb-md-0">
                      State:
                    </label>
                    <div className="p-col-12 p-md-8">
                      <InputText value={participantProfile?.bookingParticipantState} disabled />
                    </div>
                  </div>
                  <div className="p-field p-grid">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="ap" className="p-col-12 p-md-4 p-mb-md-0">
                      Amount payable:
                    </label>
                    <div className="p-col-12 p-md-8">
                      <InputText value={participantProfile?.pendingAmount} disabled />
                    </div>
                  </div>
                  <div className="p-field p-grid">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="tax" className="p-col-12 p-md-4 p-mb-md-0">
                      Tax:
                    </label>
                    <div className="p-col-12 p-md-8">
                      <InputText value={participantProfile?.taxPercentage} disabled />
                    </div>
                  </div>
                  <div className="p-field p-grid required">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="tf" className="p-col-12 p-md-4 p-mb-md-0">
                      Trek fee:
                    </label>
                    <div className="p-col-12 p-md-8">
                      <Controller
                        defaultValue={participantProfile?.trekFeeForTheUser}
                        name="trekFee"
                        control={control}
                        render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                      />
                      {errors.trekFee && (
                        <span className="p-error">
                          <p>Error:{errors.trekFee?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="p-field p-grid required">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="tf" className="p-col-12 p-md-4 p-mb-md-0">
                      Insurance Amount:
                    </label>
                    <div className="p-col-12 p-md-8">
                      <Controller
                        defaultValue={participantProfile?.insuranceAmount}
                        name="insuranceAmount"
                        control={control}
                        render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                      />
                      {errors.insuranceAmount && (
                        <span className="p-error">
                          <p>Error:{errors.insuranceAmount?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="p-field p-grid">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="tax" className="p-col-12 p-md-4 p-mb-md-0">
                      Pending Amount:
                    </label>
                    <div className="p-col-12 p-md-8">
                      <InputText value={participantProfile?.pendingAmount} disabled />
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="flex-grow-1">&nbsp;</div>
                    <div>
                      <div className="p-justify-end p-mt-2">
                        <div className="p-cpl-12 p-grid p-justify-end p-mt-4 p-mr-2">
                          <div className="">
                            <Button
                              label="Cancel"
                              icon="pi pi-times"
                              className="p-button-warning p-button-lg"
                              onClick={handleCancelClick}
                            />
                          </div>
                          <div className="">
                            <Button
                              type="submit"
                              label="Update participant"
                              icon="pi pi-check"
                              className="p-mx-2 p-button-lg p-button-secondary"
                              disabled={formState.isSubmitting}
                            >
                              {formState.isSubmitting && (
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12 p-lg-4">
                  <Card title="Booking Information">
                    <div className="d-flex align-items-center p-pb-2">
                      <div className="flex-grow-1">
                        <p>Trek Name:</p>
                      </div>
                      <div className="mx-2">{bookingModel?.trekName}</div>
                    </div>
                    <div className="d-flex align-items-center p-pb-2">
                      <div className="flex-grow-1">
                        <p>Batch Date:</p>
                      </div>
                      <div className="mx-2">{bookingModel?.startDate}</div>
                    </div>
                    <div className="d-flex align-items-center p-pb-2">
                      <div className="flex-grow-1">
                        <p>Booking State:</p>
                      </div>
                      <div className="mx-2">{bookingModel?.state}</div>
                    </div>
                    <div className="d-flex align-items-center p-pb-2">
                      <div className="flex-grow-1">
                        <p>Trek Fee:</p>
                      </div>
                      <div className="mx-2">{participantProfile?.trekFeeForTheUser}</div>
                    </div>
                    <div className="d-flex align-items-center p-pb-2">
                      <div className="flex-grow-1">
                        <p>Amount Paid:</p>
                      </div>
                      <div className="mx-2">{participantProfile?.amountPaid}</div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ParticipantEdit;
