import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DataTable, DataTableSelectionChangeParams } from 'primereact/datatable';
import { Column, ColumnProps } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import SeasonService from '../../api/SeasonService';
import { ISeasonListModel } from '../../models/index';
import withLoader from '../../helpers/withloader';

interface ColumnFieldDataProps extends ColumnProps {
  field: keyof ISeasonListModel;
}

const SeasonList: React.FC = () => {
  const history = useHistory();
  const [seasonsData, setSeasonsData] = useState<ISeasonListModel[]>();
  const [selectedCustomers1, setSelectedCustomers1] = useState<ISeasonListModel>();
  const [globalFilter1, setGlobalFilter1] = useState('');
  const [loading1, setLoading1] = useState(true);

  useEffect(() => {
    const seasonService = SeasonService;
    seasonService.get().then((data) => {
      setSeasonsData(data);
      setLoading1(false);
    });
  }, []);

  const gridTableHeader = (
    <div className="table-header">
      All Seasons
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter1}
          onChange={(e) => setGlobalFilter1(e.target.value)}
          placeholder="Global Search"
        />
      </span>
    </div>
  );

  const bodyTemplate = (data: ISeasonListModel, props: ColumnFieldDataProps) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };
  const dateFieldTemplate = (data: ISeasonListModel, props: ColumnFieldDataProps) => {
    const dateToFormat = data[props.field];
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        <Moment format="DD-MMM-YY" date={dateToFormat} />
      </>
    );
  };

  const addNewSeason = () => {
    history.push('/season/add');
  };

  const actionTemplate = (data: ISeasonListModel) => {
    return (
      <>
        <Link to={`/season/edit/${data.id}`} className="btn btn-sm btn-primary mr-1">
          <i className="pi pi-pencil icon-info-color" color="secondary" />
        </Link>
      </>
    );
  };

  return (
    <div className="p-grid table-demo">
      <div className="p-col-12">
        <div className="d-flex align-items-center mb-2">
          <div className="flex-grow-1">
            <div className="display-1">Seasons</div>
          </div>
          <div>
            <Button
              label="Create new season"
              onClick={addNewSeason}
              className="p-button-lg p-button-secondary p-button-dark"
              icon="pi pi-arrow-right"
              iconPos="right"
            />
          </div>
        </div>
        <div className="card">
          <DataTable
            scrollable
            style={{ width: '100%' }}
            value={seasonsData}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            selection={selectedCustomers1}
            onSelectionChange={(e: DataTableSelectionChangeParams) => setSelectedCustomers1(e.value)}
            globalFilter={globalFilter1}
            emptyMessage="No record(s) found."
            loading={loading1}
            header={gridTableHeader}
          >
            <Column field="trek" header="Trek" headerStyle={{ width: '280px' }} sortable body={bodyTemplate} />
            <Column
              field="startDate"
              header="Start Date"
              headerStyle={{ width: '280px' }}
              sortable
              body={dateFieldTemplate}
            />
            <Column
              field="endDate"
              header="End Date"
              headerStyle={{ width: '280px' }}
              sortable
              body={dateFieldTemplate}
            />
            <Column
              field="slopeManagers"
              header="Slope Managers"
              headerStyle={{ width: '220px' }}
              sortable
              body={bodyTemplate}
            />
            <Column
              field="transportCoordinators"
              header="Transport Managers"
              headerStyle={{ width: '220px' }}
              sortable
              body={bodyTemplate}
            />
            <Column
              headerStyle={{ width: '8rem', textAlign: 'center' }}
              bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
              body={actionTemplate}
              header="Action"
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
export default withLoader(SeasonList);
