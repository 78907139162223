import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link, RouteComponentProps } from 'react-router-dom';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Chip } from 'primereact/chip';

import { VoucherCodeModel } from '../../../../models/Voucher/VoucherCodeModel';
import { VoucherCodeState } from '../../../../models/Voucher/VoucherFilters';
import { ITrekLookupModel } from '../../../../models';

import VoucherCodeService from '../../../../api/VoucherCodeService';
import TrekService from '../../../../api/TrekService';

interface MatchParams {
  id: string;
}

const CardWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="p-grid">
      <div className="p-col-12 p-lg-8 p-md-12">{children}</div>
    </div>
  );
};

const FormControl = ({
  children,
  label,
  name,
  required = false,
}: {
  children: ReactNode;
  label: string;
  name: string;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
}) => {
  return (
    <div className="p-col-12 p-md-12">
      <div>
        <div className={`p-mb-2 ${required && 'required'}`}>
          <label htmlFor={name}>{label}:</label>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

const ErrorWrapper = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <span className="p-error">
      <p>{errorMessage}</p>
    </span>
  );
};

const VoucherCode: React.FC<RouteComponentProps<MatchParams>> = ({ history, match }) => {
  const [treks, setTreks] = useState<ITrekLookupModel[]>();
  const [selectedTreks, setSelectedTreks] = useState<number[]>([]);
  const [displayActivateVoucher, setDisplayActivateVoucher] = useState<boolean>(false);
  const [voucherCodeDetails, setVoucherCodeDetails] = useState<VoucherCodeModel>();

  const { params } = match;
  const id: number = parseInt(params.id, 10);
  const isAddMode = !id;

  const toast = useRef(null);

  useEffect(() => {
    const fetchTreks = async () => {
      try {
        const trekData = await TrekService.getByLookupFormat();
        setTreks(trekData);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('Error fetching treks:', error);
      }
    };

    fetchTreks();
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        eventCode: Yup.string().required('Title is required'),
        validTill: Yup.date()
          .transform((date) => {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
          })
          .required('Validity date is required'),
        voucherAmount: Yup.number().required('Voucher Amount is required'),
        noOfVouchers: Yup.number().required('Number of Vouchers is required'),
      }),
    [],
  );

  const methods = useForm<VoucherCodeModel>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const { handleSubmit, reset, setValue, errors, control, formState } = methods;

  useEffect(() => {
    if (!isAddMode) {
      const fetchVoucherCode = async () => {
        try {
          const trekData: VoucherCodeModel = (await VoucherCodeService.getById(id)).data;
          setVoucherCodeDetails(trekData);
          setValue('eventCode', trekData.eventCode);
          setValue('voucherAmount', trekData.voucherAmount);
          setValue('validTill', new Date(trekData.validTill));
          setValue('noOfVouchers', trekData.noOfVouchers);
          setSelectedTreks(trekData.trekIds);
          // setValue('trekId', trekData.trekId);
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.error('Error fetching treks:', error);
        }
      };

      fetchVoucherCode();
    }
  }, [id, isAddMode, setValue]);

  const createVoucherCode = (data: VoucherCodeModel) => {
    VoucherCodeService.create(data)
      .then((res) => {
        setVoucherCodeDetails(res.data);
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ` Voucher Code ${data.eventCode} created Successfully`, detail: '' });
        setSelectedTreks([]);
        setDisplayActivateVoucher(true);
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res?.response?.data?.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Creation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const updateVoucherCode = (trekId: number, data: VoucherCodeModel) => {
    const updatedData = { ...data, id: trekId, voucherCodeStatus: voucherCodeDetails!.voucherCodeStatus };
    VoucherCodeService.update(updatedData)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Voucher Code updated Successfully', detail: '' });
        history.push({ pathname: '/vouchers', state: "Generic Vouchers" });
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res?.response?.data?.message) {
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        } else {
          tst.show({
            severity: 'error',
            summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
        }
      });
  };

  const onSubmit = (data: VoucherCodeModel) => {
    const voucherCodeData = { ...data, trekIds: selectedTreks };
    return isAddMode ? createVoucherCode(voucherCodeData) : updateVoucherCode(id, voucherCodeData);
  };

  const handleTrekFilterListChange = (trekId: number, addToListAction = true) => {
    if (addToListAction && !selectedTreks.includes(trekId)) {
      setSelectedTreks((oldList) => [...oldList, trekId]);
      return;
    }
    setSelectedTreks((oldList) => oldList.filter((trek) => trek !== trekId));
  };

  const handleVoucherActivate = () => {
    VoucherCodeService.updateStatus(voucherCodeDetails!.id, VoucherCodeState.ACTIVE)
      .then(() => {
        setDisplayActivateVoucher(false);
        history.push({ pathname: '/vouchers', state: true });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...methods}
      >
        <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
          <Toast ref={toast} />
          <CardWrapper>
            <div className="d-flex align-items-center details-title">
              <div className="flex-grow-1">
                <div className="display-1">{isAddMode ? 'Add New' : 'Edit'} Voucher Code</div>
              </div>
              <div>
                <Link to="/vouchers">
                  <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }} />
                </Link>
              </div>
            </div>
          </CardWrapper>

          <CardWrapper>
            <div className="card p-fluid">
              <h4>Voucher Code details</h4>
              <div className="p-grid">
                <FormControl label="Voucher Code" name="eventCode" required>
                  <Controller
                    name="eventCode"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value }) => (
                      <InputText disabled={!isAddMode} value={value} onChange={onChange} />
                    )}
                  />
                  {errors.eventCode && <ErrorWrapper errorMessage="Voucher Code Title is required" />}
                </FormControl>

                <FormControl label="Voucher Amount" name="voucherAmount" required>
                  <Controller
                    name="voucherAmount"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value }) => (
                      <InputNumber value={value} onValueChange={(e: any) => onChange(e.value)} />
                    )}
                  />
                  {errors.voucherAmount && <ErrorWrapper errorMessage="Voucher Amount Is required" />}
                </FormControl>

                <FormControl label="No. Of Vouchers" name="noOfVouchers" required>
                  <Controller
                    name="noOfVouchers"
                    control={control}
                    defaultValue="1"
                    render={({ onChange, value }) => (
                      <InputNumber value={value} onValueChange={(e: any) => onChange(e.value)} />
                    )}
                  />
                  {errors.noOfVouchers && <ErrorWrapper errorMessage="No of Vouchers Is required" />}
                </FormControl>

                <FormControl label="Valid Date" name="validTill" required>
                  <Controller
                    name="validTill"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value }) => (
                      <Calendar
                        minDate={new Date()}
                        dateFormat="dd/mm/yy"
                        value={value}
                        onChange={(e) => onChange(e.value)}
                        monthNavigator
                        yearNavigator
                        yearRange="2020:2030"
                      />
                    )}
                  />
                  {errors.validTill && <ErrorWrapper errorMessage="Validity Is required" />}
                </FormControl>

                <FormControl label="Treks" name="trekId">
                  <Controller
                    name="trekId"
                    control={control}
                    defaultValue=""
                    render={({ value }) => (
                      <Dropdown
                        optionLabel="name"
                        optionValue="trekId"
                        value={value}
                        options={treks}
                        onChange={(e) => {
                          handleTrekFilterListChange(e.value);
                        }}
                        placeholder="Select Treks"
                        filter
                        filterBy="name"
                      />
                    )}
                  />
                </FormControl>
                {selectedTreks.length !== 0 && (
                  <div className="p-col-12 p-md-12 p-lg-6 ">
                    <div>
                      <b>Selected Treks: </b>
                      {selectedTreks.map((trekId) => {
                        const trekData = treks?.find((trek) => trek.trekId === trekId)?.name;
                        return (
                          <Chip
                            onRemove={() => handleTrekFilterListChange(trekId, false)}
                            key={trekId}
                            label={trekData}
                            removable
                            className="trek-tags"
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex p-mt-2">
                <div className="flex-grow-1" />
                <div>
                  <Button
                    type="submit"
                    label={isAddMode ? 'Generate Vouchers' : 'Update Voucher'}
                    icon="pi pi-check"
                    className="p-button-lg p-button-secondary"
                    disabled={formState.isSubmitting}
                  >
                    {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />}
                  </Button>
                </div>
              </div>
            </div>
          </CardWrapper>
        </form>
      </FormProvider>
      <Dialog
        header="Activate Voucher"
        visible={displayActivateVoucher}
        style={{ width: '50vw' }}
        onHide={() => {
          setDisplayActivateVoucher(false);
          history.push('/vouchers');
        }}
      >
        <p>
          &quot;{voucherCodeDetails?.eventCode}&quot; is created. You can Activate the voucher by clicking
          &quot;Activate Voucher&quot;
        </p>
        <Button
          type="submit"
          label="Activate Voucher"
          className="p-button-lg p-button-primary"
          onClick={handleVoucherActivate}
        />
      </Dialog>
    </>
  );
};

export default VoucherCode;
