/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import BookingParticipantService from '../../../api/BookingParticipantService';
import {
  BookingParticipantState,
  BookingState,
  IbookingsParticipantsList,
  IParticipantsFilter,
} from '../../../models/Booking/BookingParticipantsList';
import { getFlagTextColor } from '../../User/UserFlags/helper';

type ComponentProperties = {
  mode?: boolean;
  id?: number;
  history?: any;
};

const BatchWaitingListViewComponent: React.FC<ComponentProperties> = (props) => {
  // @ts-ignore
  let id: number = props.id;
  const toast = useRef(null);

  const [participants, setParticipants] = useState<IbookingsParticipantsList[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<IbookingsParticipantsList>();
  const [loading, setLoading] = useState(true);
  const [bookingId, setbookingId] = useState(0);

  useEffect(() => {
    const bookingParticipantService = BookingParticipantService;
    let filter: IParticipantsFilter = {
      bookingState: BookingState.WAITING_LIST,
      batchId: id,
    };
    bookingParticipantService.get(0, 1000, filter).then((data: any) => {
      setParticipants(data.data);
      setLoading(false);
    });
  }, [bookingId]);

  const bodyTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{data[props.field]}</span>
      </>
    );
  };

  const bodyTemplateInnerObject = (data: any, props: any) => {
    const email = data.userDetailsForDisplay[props.field];
    const textColor = getFlagTextColor(data.userDetailsForDisplay, props);
    return (
      <>
        <span style={{ color: textColor }} className="p-column-title">
          <span className="p-column-title">{email}</span>
        </span>
      </>
    );
  };

  const bodyTemplateInnerObjectFullName = (data: any, props: any) => {
    const firstName = data.userDetailsForDisplay[props.field];
    const lastName = data.userDetailsForDisplay.lastName;
    const textColor = getFlagTextColor(data.userDetailsForDisplay, props);
    return (
      <>
        <span style={{ color: textColor }} className="p-column-title">
          <span className="p-column-title">
            {firstName} {lastName}
          </span>
        </span>
      </>
    );
  };

  const participantStatusbodyTemplate = (data: any, props: any) => {
    const bookingParticipantState = data[props.field];
    switch (bookingParticipantState) {
      case BookingParticipantState.APPROVED:
        return <span className="status-success">Approved</span>;
      case BookingParticipantState.PENDING_APPROVAL:
        return <span className="status-dark">Pending</span>;
      case BookingParticipantState.FITNESS_REJECTED:
        return <span className="status-dark">Rejected</span>;
      case BookingParticipantState.WAITING_LIST:
        return <span className="status-warning">Waiting List</span>;
      default:
        return <span className="status-danger">{bookingParticipantState}</span>;
    }
  };

  const bookingStatusbodyTemplate = (data: any, props: any) => {
    const status = data[props.field];

    return (
      <>
        {status === 'COMPLETED' ? (
          <span className="status-success">Completed</span>
        ) : status === 'PAYMENT' ? (
          <span className="status-dark">Payment</span>
        ) : status === 'ADD_PARTICIPANTS' ? (
          <span className="status-dark">Add Participant</span>
        ) : status === 'WAITING_LIST' ? (
          <span className="status-dark">Waiting List</span>
        ) : status === 'CANCELLED' ? (
          <span className="status-danger">Cancelled</span>
        ) : status === 'DIFFICULT_TREK' ? (
          <span className="status-danger">Difficult Trek</span>
        ) : status === 'INACTIVE' ? (
          <span className="status-warning">Inactive</span>
        ) : (
          <span className="status-warning"> NA </span>
        )}
      </>
    );
  };

  const flagTemplate = (data: any, props: any) => {
    const textColor = getFlagTextColor(data.userDetailsForDisplay, props);
    return (
      <span style={{ color: textColor }} className="p-column-title">
        {data?.userDetailsForDisplay?.flag?.title}
      </span>
    );
  };

  const actionTemplate = (data: any) => {
    return (
      <>
        <div className="d-flex align-items-center align-jus-con p-my-2">
          <div>
            <Link to={`/bookings/view/${data.bookingId}`}>
              <i className="pi pi-eye icon-success-color" color="secondary"></i>
            </Link>
          </div>
        </div>
      </>
    );
  };

  // @ts-ignore
  return (
    <div>
      <div className="p-grid table-demo">
        <Toast ref={toast} />
        <div className="p-col-12">
          <div>
            <DataTable
              value={participants}
              paginator
              scrollable
              style={{ width: '100%' }}
              rows={10}
              dataKey="id"
              tabIndex={0}
              rowHover
              selection={selectedParticipants}
              onSelectionChange={(e: any) => setSelectedParticipants(e.value)}
              emptyMessage="No record(s) found."
              loading={loading}
            >
              <Column
                field="waitingListNumber"
                header="Waiting List #"
                headerStyle={{ width: '110px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="firstName"
                header="Full Name"
                headerStyle={{ width: '110px' }}
                sortable
                body={bodyTemplateInnerObjectFullName}
              ></Column>
              <Column
                field="email"
                header="Email"
                headerStyle={{ width: '180px' }}
                sortable
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="phone"
                header="Phone Number"
                headerStyle={{ width: '100px' }}
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="height"
                header="Height"
                headerStyle={{ width: '75px' }}
                sortable
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="weight"
                header="Weight"
                headerStyle={{ width: '75px' }}
                sortable
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="bmi"
                header="Bmi"
                headerStyle={{ width: '75px' }}
                sortable
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="age"
                header="Age"
                headerStyle={{ width: '75px' }}
                sortable
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="dob"
                header="Dob"
                headerStyle={{ width: '75px' }}
                sortable
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="gender"
                header="Gender"
                headerStyle={{ width: '75px' }}
                sortable
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="city"
                header="City"
                headerStyle={{ width: '90px' }}
                sortable
                body={bodyTemplateInnerObject}
              ></Column>
              <Column
                field="pickupLocation"
                header="Pickup Location"
                headerStyle={{ width: '130px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="dropOffLocation"
                header="Dropoff Location"
                headerStyle={{ width: '120px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="bookingParticipantState"
                header="Participant Status"
                headerStyle={{ width: '80px' }}
                sortable
                body={participantStatusbodyTemplate}
              ></Column>
              <Column
                field="bookingState"
                header="Booking Status"
                headerStyle={{ width: '80px' }}
                sortable
                body={bookingStatusbodyTemplate}
              ></Column>
              <Column field="flag" header="User Flag" headerStyle={{ width: '60px' }} body={flagTemplate} sortable />
              <Column
                headerStyle={{ width: '100px', textAlign: 'center' }}
                // bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
                body={actionTemplate}
                header="Action"
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchWaitingListViewComponent;
