/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import BatchService from '../../../api/BatchService';
import { BatchCommentModel } from '../../../models/Batch/BatchFormModel';
import moment from 'moment';

type ComponentProperties = {
  mode?: boolean;
  id?: number;
  history?: any;
};

const CommentsViewComponent: React.FC<ComponentProperties> = (props) => {
  // @ts-ignore
  let batchId: number = props.id;
  const [indexes, setIndexes] = React.useState<number[]>([]);
  const [counter, setCounter] = React.useState(0);
  const [listOfComments, setListOfComments] = useState<BatchCommentModel[]>([]);

  useEffect(() => {
    ///get from server and bind
    fetchComments();
  }, []);

  const fetchComments = () => {
    BatchService.fetchAllComments(batchId).then((commentsData) => {
      setListOfComments(commentsData.data);
      const arr = Array.from(new Array(commentsData.data.length), (x, i) => i);
      setIndexes(arr);
      setCounter(arr.length);
    });
  };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        commentText: Yup.string().required('Comment is required'),
      }),
    [],
  );

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, setValue, control, errors, formState } = useForm<any>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = (commentData: any) => {
    BatchService.saveBatchComments(batchId, commentData).then((res) => {
      fetchComments();
    });
  };
  // @ts-ignore
  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
          <div>
            <p></p>
            <Controller
              name="commentText"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => <InputTextarea autoFocus value={value} onChange={onChange} />}
            />
            {errors.commentText && (
              <span className="p-error">
                <p>Error:{errors.commentText?.message}</p>
              </span>
            )}
          </div>
          <div className="p-grid p-justify-end p-my-4 p-mx-2">
            <div className="">
              <Button
                type="submit"
                label="Post"
                icon="pi pi-check"
                className="p-button-lg p-button-secondary"
                disabled={formState.isSubmitting}
              >
                {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
              </Button>
            </div>
          </div>
        </form>
        <div className="">
          <div className="card card-w-title timeline">
            <h5>Posted Comments:</h5>
            <ul className="comment-ul">
              {indexes.map((index) => {
                const fieldName = `campsites[${index}]`;
                const comments = listOfComments[index]
                  ? listOfComments[index]
                  : {
                      commentText: '',
                      userName: '',
                      createdOn: null,
                    };
                return (
                  <li>
                    <div className="activity-link"></div>
                    {/* <div className="timeline-icon">
                                            <i className="pi pi-globe"></i>
                                        </div> */}
                    <div className="timeline-content">
                      <h3>
                        <i className="pi pi-globe"></i> {comments.userName}
                      </h3>
                      <p>{comments.commentText}</p>
                      <div className="timeline-footer">
                        <i className="pi pi-clock p-px-2"></i>
                        <span
                          style={{
                            fontStyle: 'italic',
                            color: '#ff4b4b',
                          }}
                        >
                          {moment(comments.createdOn).format('DD-MMM-YYYY')}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsViewComponent;
