import axios from 'axios';
import { IWebsiteContentMappingsModel } from '../models/WebsiteManagement/WebsiteContentMappingsModel';
import { getHeaderWithBearerToken } from '../helpers';

export const publicApiBaseUrl = `${process.env.REACT_APP_TMS_BACKEND_URL}`;

class PublicApiService {
  getUrlForwebsiteContentMappings = `${publicApiBaseUrl}/website-management/website-content-mappings`;

  getAxiosInstance = () => {
    return axios;
  };

  getAllWebsiteContentMappings = () => {
    const url = `${this.getUrlForwebsiteContentMappings}`;
    return axios
      .get<IWebsiteContentMappingsModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  deleteWebsiteContentMapping = (uid:string) => {
    const url = `${this.getUrlForwebsiteContentMappings}/${uid}`;
    return axios
      .delete(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.status);
  };

  saveWebsiteContentMapping = (uid:string,type:string) => {
    const url = `${this.getUrlForwebsiteContentMappings}`;
    const websiteContentMapping = {
      uid,
      type
    }
    return axios
      .post(url,websiteContentMapping, { headers: getHeaderWithBearerToken() })
      .then((res) => res.status);
  };

}

const publicApiService = new PublicApiService();
export default publicApiService; // exporting like a singleton
